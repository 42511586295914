import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit {

  @ViewChild('snav', { static: false }) private snav: any;
  public token;
  public idUser;
  public identity;
  screenWidth: number;
  emulatingOn: string;

  constructor(private router: Router, private userService: UserService, changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher) {
   // this.idUser = this.userService.getIdentity().IdUser;
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }
  mobileQuery: MediaQueryList;
   // tslint:disable-next-line: variable-name
   private _mobileQueryListener: () => void;

   shouldRun = true;

     // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {

    if(localStorage.getItem('emulating') === 'ENG'){
      this.emulatingOn = 'ENGLISH';
    } else {
      this.emulatingOn = 'SPANISH';
    }
  
    // set screenWidth on page load
    this.screenWidth = window.innerWidth;
    window.onresize = () => {
      // set screenWidth on screen size change
      this.screenWidth = window.innerWidth;
    };
  }

  selectEnglish() {
    localStorage.setItem('emulating', 'ENG');
    location.reload();
  }

  selectSpanish() {
    localStorage.setItem('emulating', 'SPA');
    location.reload();
  }

  logout() {
    localStorage.clear();
    this.token = null;
    this.identity = null;
    this.idUser = null;
    this.router.navigate(['login']);
  }

  closeSideNav() {
    if (this.screenWidth < 840) {
      this.snav.close();
    }
  }
}
