<mat-card>
    <div class="confirmation-messaje">
        <h1 mat-dialog-title>{{data.title}}</h1>
        <div mat-dialog-content>
            <p *ngIf="data.body != undefined">{{data.body}}{{data.number | number}}</p>
            <p>{{data.description}}</p>
            <div class="buttons-conf-message">
                <button mat-button [mat-dialog-close]="false">No</button>
                <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Si</button>
            </div>
        </div>
    </div>
</mat-card>