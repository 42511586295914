import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar, MatDialog, MatTableDataSource } from '@angular/material';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { CareersPageService } from '../../services/careers-page.service';
import { JobLevelsService } from '../../services/job-levels.service';
import { JobTypesService } from '../../services/job-types.service';
import { JobWorkplacesService } from '../../services/job-workplaces.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {

  @ViewChild('imgInput', { static: false }) myInput: ElementRef;
  public tabsContentRef!: ElementRef;


  displayedColumns: string[] = ['imgUrl', 'title', 'from', 'status', 'position', 'icons'];
  displayedColumnsJobRequests: string[] = ['fullName', 'linkedinUrl', 'salaryExpectation', 'openPosition', 'englishLevel', 'country'];
  displayedColumnsReferrals: string[] = ['candidateName', 'candidateEmail', 'phoneNumber', 'description', 'creationDate', 'position', 'referrerName', 'referrerEmail', 'resume'];
  progressBar: boolean;
  emulatingSection: string = 'Intro';

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataJobRequests) {
      this.dataJobRequests.paginator = value;
    }
    if (this.dataOpenPositions) {
      this.dataOpenPositions.paginator = value;
    }
    if (this.dataReferrals) {
      this.dataReferrals.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataJobRequests) {
      this.dataJobRequests.sort = value;
    }
    if (this.dataOpenPositions) {
      this.dataOpenPositions.sort = value;
    }
    if (this.dataReferrals) {
      this.dataReferrals.sort = value;
    }
  }




  // Forms
  introForm: FormGroup;
  openPositionsForm: FormGroup;
  formData = new FormData()

  // Data From API
  dataPrincipalPage: any;
  dataOpenPositions: any;
  positions: any;
  dataOpenPositionStatuses: any;
  dataJobRequests: any;
  dataReferrals: any;
  dataJobLevels: any;
  dataJobTypes: any;
  dataWorkplaces: any;
  dataToEdit: any;


  // Others
  dataToSend: any;
  emulatingSubsection: string = 'View Open Positions Table';
  subsectionSubtitle: string;
  actionTitle: string = 'Posiciones existentes';
  jobRequest: any;
  jobRequestPdf: any;
  pdfContainer: any;
  selectedFile: any;
  submitButtonDirty: boolean;
  imgSelected: any;
  slashOrUderscore: boolean;
  jobDescription: string;
  editingAtFirst: boolean;
  loadManatalData: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private careersPageService: CareersPageService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private jobLeverService: JobLevelsService,
    private jobTypesService: JobTypesService,
    private jobWorkplacesService: JobWorkplacesService,
    private sanitizer: DomSanitizer
  ) { }

  ngAfterViewInit() {
    if (this.emulatingSection == 'Open Job Requests Page') {
      this.dataJobRequests.paginator = this.paginator;
      this.dataJobRequests.sort = this.sort;
    }
    if (this.emulatingSection == 'View Open Positions Table') {
      this.dataOpenPositions.paginator = this.paginator;
      this.dataOpenPositions.sort = this.sort;
    }
    if (this.emulatingSection == 'referrals') {
      this.dataReferrals.paginator = this.paginator;
      this.dataReferrals.sort = this.sort;
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;

    if (this.emulatingSection == 'Open Job Requests Page') {
      this.dataJobRequests.filter = filterValue.trim().toLowerCase();
      if (this.dataJobRequests.paginator) {
        this.dataJobRequests.paginator.firstPage();
      }
    }

    if (this.emulatingSection == 'Open Positions Page') {
      this.dataOpenPositions.filter = filterValue.trim().toLowerCase();
      if (this.dataOpenPositions.paginator) {
        this.dataOpenPositions.paginator.firstPage();
      }
    }
    if (this.emulatingSection == 'referrals') {
      this.dataReferrals.filter = filterValue.trim().toLowerCase();
      if (this.dataReferrals.paginator) {
        this.dataReferrals.paginator.firstPage();
      }
    }
  }
  ngOnInit() {
    this.introForm = this.formBuilder.group({
      titleEng: ['', Validators.required],
      titleEsp: ['', Validators.required],
      introEng: ['', Validators.required],
      introEsp: ['', Validators.required]
    });

    this.careersPageService.getCareersPricipalData().subscribe((res: any) => {
      this.dataPrincipalPage = res[0];
      if (res.length === 0) {
        this.introForm = this.formBuilder.group({
          titleEng: ['', Validators.required],
          titleEsp: ['', Validators.required],
          introEng: ['', Validators.required],
          introEsp: ['', Validators.required]
        });
      } else {
        this.introForm = this.formBuilder.group({
          titleEng: [this.dataPrincipalPage.titleEng, Validators.required],
          titleEsp: [this.dataPrincipalPage.titleEsp, Validators.required],
          introEng: [this.dataPrincipalPage.introEng, Validators.required],
          introEsp: [this.dataPrincipalPage.introEsp, Validators.required]
        });
      }
    });

    this.getJobLevels();
    this.getJobTypes();
    this.getWorkplaces();
  }

  getJobLevels() {
    this.jobLeverService.getJobLevels().subscribe((res: any) => {
      this.dataJobLevels = res;
    });
  }
  getJobTypes() {
    this.jobTypesService.getJobTypes().subscribe((res: any) => {
      this.dataJobTypes = res;
    });
  }
  getWorkplaces() {
    this.jobWorkplacesService.getWorkplaces().subscribe((res: any) => {
      this.dataWorkplaces = res;
    });
  }

  // Principal Page
  submitPrincipalPageForm() {
    if (this.introForm.valid) {
      this.dataToSend = this.introForm.value;
      this.createUpdatePrincipalPage();
    }
  }

  createUpdatePrincipalPage() {
    this.progressBar = true;
    this.careersPageService.createUpdateCareersPagePrincipal(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Open Positions
  displayOpenPositionsPage() {
    this.emulatingSection = 'Open Positions Page';

    this.getOpenPositionsData();
    this.getOpenPositionsStatuses();
  }
  // Open Positions Requests
  displayJobRequestsPage() {
    this.emulatingSection = 'Open Job Requests Page';
    this.getJobRequestsData();
  }
    // Referrals
    displayReferralsPage() {
      this.emulatingSection = 'referrals';
      this.getReferralsData();
    }
    getReferralsData() {
      this.careersPageService.getReferralsData().subscribe((res: any) => {
        this.dataReferrals = res;
        this.dataReferrals = new MatTableDataSource(res);
      });
    }
  getJobRequestsData() {
    this.careersPageService.getJobRequestsData().subscribe((res: any) => {
      this.dataJobRequests = res;
      this.dataJobRequests = new MatTableDataSource(res);
    });
  }
  getJobRequestDetail(element) {
    this.jobRequest = element;
    this.jobRequestPdf = this.jobRequest.resumeFileUrl;
    let secureUrl = this.jobRequestPdf;
    let content = '<embed width="100%" height="900px" src="' + secureUrl + '" type="application/pdf">';
    this.pdfContainer = this.sanitizer.bypassSecurityTrustHtml(content);
  }

  getOpenPositionsData() {
    this.careersPageService.getOpenPositionsDataFromManatal().subscribe((res: any) => {
      this.dataOpenPositions = res;
      console.log(res);
      this.positions = this.dataOpenPositions;
      this.dataOpenPositions = new MatTableDataSource(res);
      this.loadManatalData = true;
    });
  }
  getOpenPositionsStatuses() {
    this.careersPageService.getOpenPositionsStatusesData().subscribe((res: any) => {
      this.dataOpenPositionStatuses = res;
    });
  }
  cancelButton() {
    this.slashOrUderscore = false;
    this.imgSelected = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
  }
  cancelCreatingUpdating(){
    this.getOpenPositionsData();
  }
  displayCreateNewOpenPosition(id, data, action) {
    this.slashOrUderscore = false;
    this.actionTitle = action;

    this.emulatingSubsection = 'Creating Open Position';
    this.jobDescription = data.jobDescription;
    if (id === null || id === 0) {
      var manatalId;
      if (id === 0) {
        manatalId = 0
      } else {
        manatalId = data.idManatal
      }
      this.editingAtFirst = true;
      this.imgSelected = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
      this.openPositionsForm = this.formBuilder.group({
        id: [0],
        title: [data.title, Validators.required],
        imgFile: ['', Validators.required],
        jobDescription: [data.jobRequirements, Validators.required],
        jobRequirements: [data.jobDescription, Validators.required],
        idJobLevel: [data.idJobLevel, Validators.required],
        idJobType: [data.idJobType, Validators.required],
        idWorkplace: [data.idWorkplace, Validators.required],
        color: [data.color, Validators.required],
        idManatal: [manatalId],
        urlManatal: [data.urlManatal]
      });
    } else {
      this.editingAtFirst = false;
      this.imgSelected = data.imgUrl;
      this.openPositionsForm = this.formBuilder.group({
        id: [id],
        title: [data.title, Validators.required],
        imgFile: new FormControl(''),
        jobDescription: [data.jobDescription, Validators.required],
        jobRequirements: [data.jobRequirements, Validators.required],
        idJobLevel: [data.idJobLevel, Validators.required],
        idJobType: [data.idJobType, Validators.required],
        idWorkplace: [data.idWorkplace, Validators.required],
        color: [data.color, Validators.required],
        idManatal: [data.idManatal],
        urlManatal: [data.urlManatal]
      });
    }
  }
  onSelectFile(fileInput: any) {

    this.selectedFile = fileInput.target.files[0];

    if (this.selectedFile == undefined) {

      this.imgSelected = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
    } else {

      console.log(fileInput.target.files[0]);
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = (_event) => {
        this.imgSelected = reader.result;
      }

    }
  }
  reset() {
    this.myInput.nativeElement.value = "";
  }
  submitOpenPosition(imgInput) {

    if (this.actionTitle === 'Crear Nueva posición' || this.actionTitle === 'Clonar posición') {
      this.submitButtonDirty = true;
    }

    if (this.openPositionsForm.value.title.indexOf('/') !== -1 || this.openPositionsForm.value.title.indexOf('_') !== -1
      || this.openPositionsForm.value.title.indexOf('+') !== -1
      || this.openPositionsForm.value.title.indexOf('*') !== -1
      || this.openPositionsForm.value.title.indexOf('&') !== -1 || this.openPositionsForm.value.title.indexOf('?') !== -1
      || this.openPositionsForm.value.title.indexOf('%') !== -1 || this.openPositionsForm.value.title.indexOf(':') !== -1
      || this.openPositionsForm.value.title.indexOf('<') !== -1 || this.openPositionsForm.value.title.indexOf('>') !== -1) {
      this.slashOrUderscore = true;
      this.openSnackBar('No se permiten los caracteres / _ + * & ? % : < > en el título');
    } else {
      this.slashOrUderscore = false;
      if (this.openPositionsForm.valid) {
        this.formData.append('imgFile', this.selectedFile);
        this.formData.append('id', this.openPositionsForm.value.id);
        this.formData.append('title', this.openPositionsForm.value.title);
        this.formData.append('jobDescription', this.openPositionsForm.value.jobDescription);
        this.formData.append('jobRequirements', this.openPositionsForm.value.jobRequirements);
        this.formData.append('idJobLevel', this.openPositionsForm.value.idJobLevel);
        this.formData.append('idJobType', this.openPositionsForm.value.idJobType);
        this.formData.append('idWorkplace', this.openPositionsForm.value.idWorkplace);
        this.formData.append('color', this.openPositionsForm.value.color);
        this.formData.append('idManatal', this.openPositionsForm.value.idManatal);
        this.formData.append('urlManatal', this.openPositionsForm.value.urlManatal);

        this.createUpdateOpenPosition(imgInput);
      } else {
        this.openSnackBar('Todos los campos son requeridos');
      }
    }

  }

  createUpdateOpenPosition(imgInput) {
    this.progressBar = true;
    this.loadManatalData = false;
    console.log(this.openPositionsForm.value.jobDescription);

    this.careersPageService.createUpdateOpenPosition(this.formData).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.deleteFormData();
      this.openPositionsForm.reset();
      this.emulatingSubsection = 'View Open Positions Table';
      this.actionTitle = 'Posiciones existentes';
      this.progressBar = false;
      this.submitButtonDirty = false;

      this.getOpenPositionsData();
      this.selectedFile = null;
      imgInput.value = null;
    }, err => {
      console.log(this.openPositionsForm.value.title);
      if(err.error.includes('Ya existe una posición con el titulo')){
        this.progressBar = false;
        this.openSnackBar(err.error);
      } else {
        this.openSnackBar('Error de servidor');
      }
      this.deleteFormData();
    });
  }
  deleteFormData() {
    this.formData.delete('imgFile');
    this.formData.delete('id');
    this.formData.delete('title');
    this.formData.delete('jobDescription');
    this.formData.delete('jobRequirements');
    this.formData.delete('idJobLevel');
    this.formData.delete('idJobType');
    this.formData.delete('idWorkplace');
    this.formData.delete('color');
    this.formData.delete('idManatal');
    this.formData.delete('urlManatal');
  }
  changeOpenPositionStatus(id, statusId, statusName) {
    let statusMessage = '';
    if (statusName === 'Published') {
      statusMessage = '¡La posición se publicó con exito!'
    } else {
      statusMessage = '¡La posición se despublicó con exito!'
    }
    this.loadManatalData = false;
    this.careersPageService.changeOpenPositionStatus(id, statusId).subscribe((response) => {
      this.openSnackBar(statusMessage);
      this.getOpenPositionsData();
    }, err => {
      this.openSnackBar('¡Error de servidor!');
      console.log(err);
    });
  }
  changePositionToOpenPosition(currentPosition, newPosition) {
    this.loadManatalData = false;
    this.careersPageService.changePositionToOpenPosition(currentPosition, newPosition).subscribe((response) => {
      this.openSnackBar('Se cambió a la posición: ' + newPosition);
      this.getOpenPositionsData();
    }, err => {
      this.openSnackBar('¡Error de servidor!');
      console.log(err);
    });
  }

  delete(id) {
    this.openConfirmationMessageDelete(id);
  }
  openConfirmationMessageDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Elemento', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete
        this.loadManatalData = false;
        this.careersPageService.deleteOpenPosition(id).subscribe((response) => {
          this.openSnackBar('¡Eliminado correctamente!');
          this.getOpenPositionsData();
          this.progressBar = false;
        }, err => {
          this.openSnackBar('¡Error de servidor!');
        });
      }
    });
  }
  // Others



  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }

}
