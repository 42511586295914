import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class OurServicesPageService {

  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) { 
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
  }

  getOurServicesPricipalData() {
    return this.http.get(this.url + 'OurServicePrincipals/GetOurServicesData', this.httpOptions);
  }
  
  createUpdateOurServicesPrincipal(data) {
    return this.http.post(this.url + 'OurServicePrincipals/CreateUpdateOurServicePrincipal', data, this.httpOptions);
  }
  getTeamsData() {
    return this.http.get(this.url + 'OurServicesTeams/GetTeams', this.httpOptions);
  }
  createUpdateTeam(data) {
    return this.http.post(this.url + 'OurServicesTeams/CreateUpdateTeam', data, this.httpOptions);
  }
  deleteTeam(id){
    const httpOptions = {
      params: new HttpParams().set('idTeam', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'OurServicesTeams/DeleteTeam', id, httpOptions);
  }

  // PROFILES

         // Expertises
  getProfilesExpertiseData() {
    return this.http.get(this.url + 'OurServicesProfiles/GetProfileExpertises', this.httpOptions);
  }

  createUpdateExpertise(data) {
    return this.http.post(this.url + 'OurServicesProfiles/CreateUpdateExpertise', data, this.httpOptions);
  }
  deleteExpertise(id){
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'OurServicesProfiles/DeleteExpertise', id, httpOptions);
  }
   // Categories
   getProfilesCategoryData() {
    return this.http.get(this.url + 'OurServicesProfiles/GetProfileCategories', this.httpOptions);
  }

  createUpdateCategories(data) {
    return this.http.post(this.url + 'OurServicesProfiles/CreateUpdateCategory', data, this.httpOptions);
  }
  deleteCategories(id){
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'OurServicesProfiles/DeleteCategories', id, httpOptions);
  }
  // Tecnologies
  getTechnologiesData() {
    return this.http.get(this.url + 'OurServicesTechnologies/GetTechnologies', this.httpOptions);
  }

  createUpdateTechnology(data) {
    return this.http.post(this.url + 'OurServicesTechnologies/CreateUpdateTechnology', data, this.httpOptions);
  }
  deleteTechnology(id){
    const httpOptions = {
      params: new HttpParams().set('idTech', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'OurServicesTechnologies/DeleteTechnology', id, httpOptions);
  }
}
