import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class LoggedoutGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) { }
  canActivate() {
    const token = this.userService.getTockenLocalStorage();
    if (token == null) {
        return true;
    } else {
        this.router.navigate(['inicio']);
    }
}
  
}
