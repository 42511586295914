<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <p>Crear un tag nuevo</p>
    <mat-form-field appearance="fill">
      <mat-label>Nombre</mat-label>
      <input matInput placeholder="..." formControlName="name" required>
      <mat-error *ngIf="form.get('name').hasError('required')">Nombre es requerido.</mat-error>
      <mat-error *ngIf="form.get('name').hasError('unique')">Ese tag ya existe.</mat-error>
      <mat-error *ngIf="form.get('name').hasError('unknown')">Ocurrio un error.</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button type="button" (click)="onCancel()">Cancel</button>
    <button mat-button type="submit">Create</button>
  </div>
</form>