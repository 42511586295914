import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AcademyPageService } from '../../services/academy-page.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';
import { element } from 'protractor';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-oceans-academy',
  templateUrl: './oceans-academy.component.html',
  styleUrls: ['./oceans-academy.component.css']
})
export class OceansAcademyComponent implements OnInit {

  displayedColumns: string[] = ['titleEng', 'titleEsp', 'icons'];
  public pageForm: FormGroup;
  public certificationForm: FormGroup;
  public termsConditionsForm: FormGroup;
  dataToSend: any;
  progressBar: boolean;
  academyEnglishData: any;
  academySpanishData: any;
  certificationPage: boolean = false;
  creatingCertification: boolean = false;
  dataCertifications: any;
  dataTermsConditions: any;
  fileToUpload: File;
  formData = new FormData();
  certificationsSection: string;

  constructor(public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private academyPageService: AcademyPageService,
    private snackBar: MatSnackBar) {
    this.progressBar = false;
    this.certificationsSection = "certification table";
  }

  ngOnInit() {
    if (localStorage.getItem('emulating') === 'ENG') {
      this.getEnglishData();
    } else {
      this.getSpanishData();
    }
  }
  initializeForm() {
    this.pageForm = this.formBuilder.group({
      bannerTitle: ['', Validators.required],
      bannerIntro: ['', Validators.required],
      firstSectionIntro: ['', Validators.required],
      secondSectionTitle: ['', Validators.required],
      secondSectionDescription: ['', Validators.required],
      thirdSectionCertificationsTitle: ['', Validators.required],
      fourthSectionTestimonyDescription: ['', Validators.required]
    });
  }
  getEnglishData() {
    this.initializeForm();
    this.academyPageService.getAcademyEnglishData().subscribe((res: any) => {
      this.academyEnglishData = res;
      if (res.length > 0) {
        this.pageForm = this.formBuilder.group({
          bannerTitle: [this.academyEnglishData[0].bannerTitle, Validators.required],
          bannerIntro: [this.academyEnglishData[0].bannerIntro, Validators.required],
          firstSectionIntro: [this.academyEnglishData[0].firstSectionIntro, Validators.required],
          secondSectionTitle: [this.academyEnglishData[0].secondSectionTitle, Validators.required],
          secondSectionDescription: [this.academyEnglishData[0].secondSectionDescription, Validators.required],
          thirdSectionCertificationsTitle: [this.academyEnglishData[0].thirdSectionCertificationsTitle, Validators.required],
          fourthSectionTestimonyDescription: [this.academyEnglishData[0].fourthSectionTestimonyDescription, Validators.required]
        });
      }
    });
  }


  getCertifications() {
    this.academyPageService.getCertifications().subscribe((res: any) => {
      this.dataCertifications = res;
      console.log(this.dataCertifications);
    });
  }


  createCertificationForm(id) {
    this.certificationForm = this.formBuilder.group({
      id: [id],
      titleEng: [this.dataCertifications.titleEng, Validators.required],
      bannerTitleEng: [this.dataCertifications.bannerTitleEng, Validators.required],
      imgUrlBanner: [this.dataCertifications.imgUrlBanner, Validators.required],
      imgUrlBannerMobile: [this.dataCertifications.imgUrlBannerMobile, Validators.required],
      imgUrlThumbnail: [this.dataCertifications.imgUrlThumbnail, Validators.required],
      firstSectionIntroEng: [this.dataCertifications.firstSectionIntroEng, Validators.required],
      secondSectionExamFormatTitleEng: [this.dataCertifications.secondSectionExamFormatTitleEng, Validators.required],
      secondSectionExamFormatDetailsEng: [this.dataCertifications.secondSectionExamFormatDetailsEng, Validators.required],
      secondSectionDurationTitleEng: [this.dataCertifications.secondSectionDurationTitleEng, Validators.required],
      secondSectionDurationDetailsEng: [this.dataCertifications.secondSectionDurationDetailsEng, Validators.required],
      secondSectionPriceTitleEng: [this.dataCertifications.secondSectionPriceTitleEng, Validators.required],
      secondSectionPriceDetailsEng: [this.dataCertifications.secondSectionPriceDetailsEng],
      titleEsp: [this.dataCertifications.titleEsp, Validators.required],
      bannerTitleEsp: [this.dataCertifications.bannerTitleEsp, Validators.required],
      firstSectionIntroEsp: [this.dataCertifications.firstSectionIntroEsp, Validators.required],
      secondSectionExamFormatTitleEsp: [this.dataCertifications.secondSectionExamFormatTitleEsp, Validators.required],
      secondSectionExamFormatDetailsEsp: [this.dataCertifications.secondSectionExamFormatDetailsEsp, Validators.required],
      secondSectionDurationTitleEsp: [this.dataCertifications.secondSectionDurationTitleEsp, Validators.required],
      secondSectionDurationDetailsEsp: [this.dataCertifications.secondSectionDurationDetailsEsp, Validators.required],
      secondSectionPriceTitleEsp: [this.dataCertifications.secondSectionPriceTitleEsp, Validators.required],
      secondSectionPriceDetailsEsp: [this.dataCertifications.secondSectionPriceDetailsEsp],
      payPalUrlNormal: [this.dataCertifications.payPalUrlNormal, Validators.required],
      payPalUrlPromo: [this.dataCertifications.payPalUrlPromo, Validators.required],
      priceNormal: [this.dataCertifications.priceNormal, Validators.required],
      pricePromo: [this.dataCertifications.pricePromo, Validators.required],
      recomendedForEsp: [this.dataCertifications.recomendedForEsp, Validators.required],
      recomendedForEng: [this.dataCertifications.recomendedForEng, Validators.required],
      prerequisitesEsp: [this.dataCertifications.prerequisitesEsp, Validators.required],
      prerequisitesEng: [this.dataCertifications.prerequisitesEng, Validators.required]
    });
  }
  getSpanishData() {
    this.initializeForm();
    this.academyPageService.getAcademySpanishData().subscribe((res: any) => {
      this.academySpanishData = res;
      if (res.length > 0) {
        this.pageForm = this.formBuilder.group({
          bannerTitle: [this.academySpanishData[0].bannerTitle, Validators.required],
          bannerIntro: [this.academySpanishData[0].bannerIntro, Validators.required],
          firstSectionIntro: [this.academySpanishData[0].firstSectionIntro, Validators.required],
          secondSectionTitle: [this.academySpanishData[0].secondSectionTitle, Validators.required],
          secondSectionDescription: [this.academySpanishData[0].secondSectionDescription, Validators.required],
          thirdSectionCertificationsTitle: [this.academySpanishData[0].thirdSectionCertificationsTitle, Validators.required],
          fourthSectionTestimonyDescription: [this.academySpanishData[0].fourthSectionTestimonyDescription, Validators.required]
        });
      }
    });
  }
  displayPrincipalPage() {
    this.certificationPage = false;
    this.creatingCertification = false;
  }
  displayTermsAndConditions() {
    this.getTermsConditionsData();
  }
  getTermsConditionsData() {
    this.academyPageService.getTermsConditions().subscribe((res: any) => {
      console.log(res);
      if (res.length === 0) {
        this.termsConditionsForm = this.formBuilder.group({
          titleEng: ['', Validators.required],
          descriptionEng: ['', Validators.required],
          titleEsp: ['', Validators.required],
          descriptionEsp: ['', Validators.required]
        });
      } else {
        this.dataTermsConditions = res[0];
        this.termsConditionsForm = this.formBuilder.group({
          titleEng: [this.dataTermsConditions.titleEng, Validators.required],
          descriptionEng: [this.dataTermsConditions.descriptionEng, Validators.required],
          titleEsp: [this.dataTermsConditions.titleEsp, Validators.required],
          descriptionEsp: [this.dataTermsConditions.descriptionEsp, Validators.required]
        });
      }
      this.certificationsSection = 'terms and conditions';

      console.log(this.dataTermsConditions);
    });
  }
  displayCertificationsPage() {
    this.certificationPage = true;
    this.creatingCertification = false;
    this.getCertifications();
  }
  displayCreateCertification(id) {
    this.certificationsSection = 'creating certification';
    this.creatingCertification = true;
    this.createCertificationForm(id);
  }
  displayViewCertifications() {
    this.certificationsSection = 'certifications table';
    this.getCertifications();
    this.creatingCertification = false;

  }
  editCertification(element) {

    this.academyPageService.getIndividualCertification(element.id).subscribe((res: any) => {
      this.dataCertifications = res;
      console.log(this.dataCertifications);
      this.displayCreateCertification(this.dataCertifications.id);
    });

  }

  onSubmit() {
    this.dataToSend = this.pageForm.value;

    console.log(this.dataToSend);
    if (this.pageForm.valid) {
      this.progressBar = true;
      if (localStorage.getItem('emulating') === 'ENG') {
        this.createEnglish();
      } else {
        this.createSpanish();
      }

    }
  }

  submitCertification() {

    if (this.certificationForm.valid) {
      this.dataToSend = {
        id: this.certificationForm.value.id,
        titleEng: this.certificationForm.value.titleEng,
        imgUrlBanner: this.dataCertifications.imgUrlBanner,
        imgUrlBannerMobile: this.dataCertifications.imgUrlBannerMobile,
        imgUrlThumbnail: this.dataCertifications.imgUrlThumbnail,
        bannerTitleEng: this.certificationForm.value.bannerTitleEng,
        firstSectionIntroEng: this.certificationForm.value.firstSectionIntroEng,
        secondSectionExamFormatTitleEng: this.certificationForm.value.secondSectionExamFormatTitleEng,
        secondSectionExamFormatDetailsEng: this.certificationForm.value.secondSectionExamFormatDetailsEng,
        secondSectionDurationTitleEng: this.certificationForm.value.secondSectionDurationTitleEng,
        secondSectionDurationDetailsEng: this.certificationForm.value.secondSectionDurationDetailsEng,
        secondSectionPriceTitleEng: this.certificationForm.value.secondSectionPriceTitleEng,
        secondSectionPriceDetailsEng: this.certificationForm.value.secondSectionPriceDetailsEng,
        titleEsp: this.certificationForm.value.titleEsp,
        bannerTitleEsp: this.certificationForm.value.bannerTitleEsp,
        firstSectionIntroEsp: this.certificationForm.value.firstSectionIntroEsp,
        secondSectionExamFormatTitleEsp: this.certificationForm.value.secondSectionExamFormatTitleEsp,
        secondSectionExamFormatDetailsEsp: this.certificationForm.value.secondSectionExamFormatDetailsEsp,
        secondSectionDurationTitleEsp: this.certificationForm.value.secondSectionDurationTitleEsp,
        secondSectionDurationDetailsEsp: this.certificationForm.value.secondSectionDurationDetailsEsp,
        secondSectionPriceTitleEsp: this.certificationForm.value.secondSectionPriceTitleEsp,
        secondSectionPriceDetailsEsp: this.certificationForm.value.secondSectionPriceDetailsEsp,
        payPalUrlNormal: this.certificationForm.value.payPalUrlNormal,
        payPalUrlPromo: this.certificationForm.value.payPalUrlPromo,
        priceNormal: this.certificationForm.value.priceNormal,
        pricePromo: this.certificationForm.value.pricePromo,
        recomendedForEsp: this.certificationForm.value.recomendedForEsp,
        recomendedForEng: this.certificationForm.value.recomendedForEng,
        prerequisitesEsp: this.certificationForm.value.prerequisitesEsp,
        prerequisitesEng: this.certificationForm.value.prerequisitesEng
      };

      this.createCertification();
    }
  }
  submitTermsConditions() {
    if (this.termsConditionsForm.valid) {
      this.dataToSend = {
        titleEng: this.termsConditionsForm.value.titleEng,
        titleEsp: this.termsConditionsForm.value.titleEsp,
        descriptionEng: this.termsConditionsForm.value.descriptionEng,
        descriptionEsp: this.termsConditionsForm.value.descriptionEsp
      };
      this.createUpdateTermsConditions();
    }
  }
  createUpdateTermsConditions() {
    this.progressBar = true;
    this.academyPageService.createUpdateTermsConditions(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      console.log(this.dataToSend);
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  getFiles(files: any) {
    this.fileToUpload = files[0];
    this.formData.append('file', this.fileToUpload, this.fileToUpload.name);

    console.log(this.fileToUpload);
    console.log(this.fileToUpload.name);
  }

  createEnglish() {
    this.academyPageService.createEnglish(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  createSpanish() {
    this.academyPageService.createSpanish(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  createCertification() {
    this.progressBar = true;
    console.log(this.dataToSend);

    this.academyPageService.createCertification(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.creatingCertification = false;
      this.progressBar = false;
      this.progressBar = false;
      this.getCertifications();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });


  }

  deleteCertification(idEngCertification) {
    this.openConfirmationMessage(idEngCertification);
  }

  openConfirmationMessage(idEngCertification): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Certificación', 'description': '¿Deseas eliminar la certificación?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete spanish certification
        this.academyPageService.deleteCertification(idEngCertification).subscribe((response) => {
          this.openSnackBar('¡Certificación eliminada!');
          this.getCertifications();
          this.progressBar = false;
        }, err => {
          this.openSnackBar('¡Error de servidor!');
          console.log(err);
        });


      }
    });
  }


  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }
}
