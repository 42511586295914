import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from 'ng2-ckeditor';

import { MaterialModule } from '../../material/material.module';
import { OurBlogComponent } from './our-blog.component';
import { OurBlogTagsComponent } from './our-blog-tags/our-blog-tags.component';
import { OurBlogCreateDialogComponent } from './our-blog-create-dialog/our-blog-create-dialog.component';
import { OurBlogCreateButtonComponent } from './our-blog-create-button/our-blog-create-button.component';
import { OurBlogConfirmDeleteComponent } from './our-blog-confirm-delete/our-blog-confirm-delete.component';
import { OurBlogDeleteButtonComponent } from './our-blog-delete-button/our-blog-delete-button.component';
import { OurBlogTagAutocompleteComponent } from './our-blog-tag-autocomplete/our-blog-tag-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule
  ],
  declarations: [
    OurBlogComponent,
    OurBlogTagsComponent,
    OurBlogCreateDialogComponent,
    OurBlogCreateButtonComponent,
    OurBlogConfirmDeleteComponent,
    OurBlogDeleteButtonComponent,
    OurBlogTagAutocompleteComponent
  ],
  entryComponents: [
    OurBlogCreateDialogComponent,
    OurBlogConfirmDeleteComponent
  ]
})
export class OurBlogModule { }
