import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class BlogTagService {
  public url: string;
  public urlPages: string;
  public token: string;
  private httpOptions = {};

  constructor(private http: HttpClient) { 
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');
    this.httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${this.token}`
      })
    };
  }

  getBlogTags() {
    return this.http.get<{ id: number; name: string; }[]>(this.getFullUrl('BlogTag/GetBlogTags'), this.httpOptions);
  }

  createBlogTag(name: string) {
    return this.http.post(this.getFullUrl('BlogTag/CreateTag'), { Name: name }, this.httpOptions);
  }

  deleteBlogTag(id: number) {
    return this.http.delete(this.getFullUrl(`BlogTag/DeleteTag/${id}`), this.httpOptions);
  }

  private getFullUrl(suf: string) {
    return `${this.url}${suf}`;
  }
}