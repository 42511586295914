import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class AcademyPageService {

  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) {

    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
  }

  getAcademyEnglishData() {
    return this.http.get(this.url + 'AcademyP/GetAcademyEnglishData', this.httpOptions);
  }
  getAcademySpanishData() {
    return this.http.get(this.url + 'AcademyP/GetAcademySpanishData', this.httpOptions);
  }
  getIndividualCertification(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.get(this.url + 'AcademyPCertifications/GetIndividualCertification', httpOptions);
  }
  getCertifications() {
    return this.http.get(this.url + 'AcademyPCertifications/GetCertifications', this.httpOptions);
  }

  createEnglish(data) {
    return this.http.post(this.url + 'AcademyP/CreateAcademyEnglish', data, this.httpOptions);
  }
  createSpanish(data) {
    return this.http.post(this.url + 'AcademyP/CreateAcademySpanish', data, this.httpOptions);
  }
  createCertification(data) {
    return this.http.post(this.url + 'AcademyPCertifications/CreateCertification', data, this.httpOptions);
  }
  createUpdateTermsConditions(data) {
    return this.http.post(this.url + 'TermsConditions/CreateUpdateTermsConditions', data, this.httpOptions);
  }
  getTermsConditions() {
    return this.http.get(this.url + 'TermsConditions/GetTermsAndConditions', this.httpOptions);
  }

  
  deleteCertification(idEngCertification){
    const httpOptions = {
      params: new HttpParams().set('idEngCertification', idEngCertification),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'AcademyPCertifications/DeleteCertification', idEngCertification, httpOptions);
  }

}
