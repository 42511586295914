import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class AboutUsPageService {

  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) { 
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
  }

  // PRINCIPAL PAGE
  getAboutUsPricipalData() {
    return this.http.get(this.url + 'AboutUsPagesPrincipal/GetAboutUsPrincipalData', this.httpOptions);
  }
  createUpdateAboutUsPagePrincipal(data) {
    return this.http.post(this.url + 'AboutUsPagesPrincipal/CreateUpdateAboutUsPagePrincipal', data, this.httpOptions);
  }
  // Our Values
  getOurValuesData() {
    return this.http.get(this.url + 'AboutUsPagesOurValues/GetOurValuesData', this.httpOptions);
  }

  createUpdateOurValue(data) {
    return this.http.post(this.url + 'AboutUsPagesOurValues/CreateUpdateOurValues', data, this.httpOptions);
  }
  deleteValue(id){
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'AboutUsPagesOurValues/DeleteValue', id, httpOptions);
  }
  // Industries
  getIndustriesData() {
    return this.http.get(this.url + 'AboutUsPagesIndustries/GetIndustryData', this.httpOptions);
  }

  createUpdateIndustry(data) {
    return this.http.post(this.url + 'AboutUsPagesIndustries/CreateUpdateIndustry', data, this.httpOptions);
  }
  deleteIndustry(id){
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'AboutUsPagesIndustries/DeleteIndustry', id, httpOptions);
  }
}
