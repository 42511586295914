<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="emulatingSection = 'Intro'">Intro principal</button> &nbsp;
        <button (click)="emulatingSection = 'Our Values'; getOurValuesData(); emulatingSubsection = 'View Our Values Table'">Nuestros Valores</button> &nbsp;
        <button (click)="emulatingSection = 'Industries'; getIndustriesData(); emulatingSubsection = 'View industry Table'">Industries</button> &nbsp;
    </div> <br> <br> <br>
    <!--PRINCIPAL PAGE-->
    <div *ngIf="emulatingSection == 'Intro'">
        <h4>Introducción principal de About Us page</h4>
        <br>
        <form [formGroup]="introForm" id="introForm" (ngSubmit)="submitPrincipalPageForm()">

            <!-- ENGLISH-->
            <span class="text-success subtitle">Información en Inglés</span>
            <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título</mat-label>
                <input name="titleEng" formControlName="titleEng" #title1Eng matInput maxlength="100">
                <mat-hint align="end">{{title1Eng.value.length}} / 100</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="introEng" formControlName="introEng"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Texto desarrollo </mat-label>
                <textarea class="height-inputs" matInput name="secondIntroEng" formControlName="secondIntroEng"></textarea>
                <mat-error>El texto es requerido</mat-error>
            </mat-form-field> <br>

            <!-- SPANISH-->
            <span class="text-success subtitle">Información en Español</span>
            <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título</mat-label>
                <input name="titleEsp" formControlName="titleEsp" #title1Esp matInput maxlength="100">
                <mat-hint align="end">{{title1Esp.value.length}} / 100</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="introEsp" formControlName="introEsp"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Texto desarrollo </mat-label>
                <textarea class="height-inputs" matInput name="secondIntroEsp" formControlName="secondIntroEsp"></textarea>
                <mat-error>El texto es requerido</mat-error>
            </mat-form-field> <br>

        </form>
        <div class="submit-button-container">
            <button form="introForm" mat-button>Guardar cambios</button>
        </div>
    </div>
    <!-- OUR VALUES -->

    <div *ngIf="emulatingSection == 'Our Values'">
        <h3>Nuestros Valores</h3>
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View Our Values Table'; actionTitle = 'Valores Existentes';">Ver valores existentes</button> &nbsp;
            <button (click)="displayCreateNewOurValue('0', '0', 'Crear Nueva valor')">Agregar nuevo</button> &nbsp;

            <br> <br>
        </div>
        <h4><strong>{{actionTitle}}</strong></h4>
        <div *ngIf="emulatingSubsection == 'View Our Values Table'">
            <table mat-table [dataSource]="dataOurValues" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Valor en Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Valor en Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'Our Values')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayCreateNewOurValue(element.id, element, 'Editar Valor')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>


        <!-- CREATE VALUES-->
        <div *ngIf="emulatingSubsection == 'Creating Value'">

            <form [formGroup]="ourValuesForm" id="ourValuesForm" (ngSubmit)="submitOurValue()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #titleOurValueEng matInput maxlength="200">
                    <mat-hint align="end">{{titleOurValueEng.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción </mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen para Desktop</mat-label>
                    <input name="imgUrl" formControlName="imgUrl" #urlImage matInput maxlength="3000">
                    <mat-hint align="end">{{urlImage.value.length}} / 3000</mat-hint>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen para Mobile</mat-label>
                    <input name="imgUrlMobile" formControlName="imgUrlMobile" #urlImageMobile matInput maxlength="3000">
                    <mat-hint align="end">{{urlImageMobile.value.length}} / 3000</mat-hint>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #titleOurValueEsp matInput maxlength="200">
                    <mat-hint align="end">{{titleOurValueEsp.value.length}} / 200</mat-hint>
                    <mat-error>La descripción es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción </mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

            </form>
            <div class="submit-button-container">
                <button form="ourValuesForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>

    <!-- Industries -->

    <div *ngIf="emulatingSection == 'Industries'">
        <h3>Industrias</h3>
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View industry Table'; actionTitle = 'Valores Existentes';">Ver valores existentes</button> &nbsp;
            <button (click)="displayCreateNewIndustry('0', '0', 'Crear Nueva Industria')">Agregar nuevo</button> &nbsp;

            <br> <br>
        </div>
        <h4><strong>{{actionTitle}}</strong></h4>
        <div *ngIf="emulatingSubsection == 'View industry Table'">
            <table mat-table [dataSource]="dataIndustries" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Valor en Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Valor en Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'Industry')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayCreateNewIndustry(element.id, element, 'Editar Industria')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>


        <!--CREATE INDUSTRY-->
        <div *ngIf="emulatingSubsection == 'Creating Industry'">

            <form [formGroup]="industryForm" id="industryForm" (ngSubmit)="submitIndustry()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #titleOurValueEng matInput maxlength="200">
                    <mat-hint align="end">{{titleOurValueEng.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción </mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen para Desktop</mat-label>
                    <input name="imgUrl" formControlName="imgUrl" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen para Mobile</mat-label>
                    <input name="imgUrlMobile" formControlName="imgUrlMobile" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #titleOurValueEsp matInput maxlength="200">
                    <mat-hint align="end">{{titleOurValueEsp.value.length}} / 200</mat-hint>
                    <mat-error>La descripción es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción </mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

            </form>
            <div class="submit-button-container">
                <button form="industryForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>


</div>