import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class CareersPageService {

  public url: string;
  public urlPages: string;
  private httpOptions;
  private httpOptionsSendFile;
  public token: string;
  private headersObject;
  private headersObjectAuthorized;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    this.headersObjectAuthorized = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
    this.httpOptionsSendFile = {
      headers: this.headersObjectAuthorized
    };
  }

  // PRINCIPAL PAGE
  getCareersPricipalData() {
    return this.http.get(this.url + 'CareersPagesPrincipal/GetCareersPrincipalData', this.httpOptions);
  }
  createUpdateCareersPagePrincipal(data) {
    return this.http.post(this.url + 'CareersPagesPrincipal/CreateUpdateCareersPagePrincipal', data, this.httpOptions);
  }

  // Open positions
  getOpenPositionsData() {
    return this.http.get(this.url + 'CareersPagesOpenPositions/GetOpenPositionsData', this.httpOptions);
  }
  getOpenPositionsDataFromManatal() {
    return this.http.get(this.url + 'CareersPagesOpenPositions/GetOpenPositionsFromManatal', this.httpOptions);
  }

  getOpenPositionsStatusesData() {
    return this.http.get(this.url + 'CareersPagesOpenPositions/GetOpenPositionsStatusesData', this.httpOptions);
  }

  createUpdateOpenPosition(data) {
    return this.http.post(this.url + 'CareersPagesOpenPositions/CreateUpdateOpenPosition', data, this.httpOptionsSendFile);
  }
  deleteOpenPosition(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'CareersPagesOpenPositions/DeleteOpenPosition', id, httpOptions);
  }

  changeOpenPositionStatus(id, statusId){
    let params = new HttpParams().set('id', id).set('statusId', statusId);
    const httpOptions = {
      params: params,
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'CareersPagesOpenPositions/ChangeStatusToOpenPosition',params, httpOptions);
  }
  changePositionToOpenPosition(currentPosition, newPosition){
    let params = new HttpParams().set('currentPosition', currentPosition).set('newPosition', newPosition);
    const httpOptions = {
      params: params,
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'CareersPagesOpenPositions/ChangePositionToOpenPosition',params, httpOptions);
  }

  // Open positions Requests
  getJobRequestsData() {
    return this.http.get(this.url + 'CareersJobRequests/GetJobRequests', this.httpOptions);
  }
  getReferralsData() {
    return this.http.get(this.url + 'Referrals/GetReferrals', this.httpOptions);
  }
}
