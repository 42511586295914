<mat-form-field class="obta">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let tag of selectedTags"
      (removed)="remove(tag)"
    >
      {{ tag.name }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input
      placeholder="Buscar tags..."
      #tagsInput
      [formControl]="tagsCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    />
  </mat-chip-list>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="select($event)">
    <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
      {{ tag.name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>