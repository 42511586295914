import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { BlogTagService } from '../../../services/blog-tag.service';

@Component({
  selector: 'app-our-blog-confirm-delete',
  templateUrl: './our-blog-confirm-delete.component.html'
})
export class OurBlogConfirmDeleteComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<OurBlogConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data,
    private readonly blogTagService: BlogTagService,
    private readonly snackBar: MatSnackBar
  ) {}

  onConfirm() {
    this.blogTagService.deleteBlogTag(this.data.tag.id)
      .subscribe(
        () => this.onCancel(true),
        () => {
          this.snackBar.open('Ocurrio un error.');
          this.onCancel(true);
        }
      );
  }

  onCancel(refetch = false) {
    this.dialogRef.close(refetch);
  }
}
