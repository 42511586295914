<div id="user-background">
    <div id="user-form">
        <br>
        <div class="row justify-content-center alert-message" *ngIf="status == 'error'">
            Credenciales incorrectas
        </div>
        <br>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-form-field class="example-full-width">
                <mat-label>Correo</mat-label>
                <input name="email" type="email" maxlength="80" matInput value="" formControlName="email">
                <mat-error *ngIf="loginForm.get('email').hasError('required')">El correo es requerido</mat-error>
                <mat-error *ngIf="loginForm.get('email').hasError('email')">Ingresa un correo valido</mat-error>
            </mat-form-field>
            <br>
            <br>
            <mat-form-field class="example-full-width">
                <mat-label>Contraseña</mat-label>
                <input maxlength="50" name="password" type="password" matInput value="" formControlName="password">
                <mat-error>La contraseña es requerida</mat-error>
            </mat-form-field>

            <br>

            <button id="userBtn" type="submit" mat-button>Iniciar Sesión</button>
            <ngx-spinner bdColor="rgba(8, 74, 88 , 0.7)" size="medium" color="white)" type="square-spin">
                <p style="font-size: 20px; color: white">INICIANDO SESIÓN...</p>
            </ngx-spinner>
            <br>
            <br>

        </form>
    </div>
</div>