import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class JobLevelsService {

  public url: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
  }


  getJobLevels() {
    return this.http.get(this.url + 'JobLevels/GetJobLevels', this.httpOptions);
  }
}
