import { Component, OnInit } from '@angular/core';

import { BlogTagService } from '../../../services/blog-tag.service';

@Component({
  selector: 'app-our-blog-tags',
  templateUrl: './our-blog-tags.component.html',
  styleUrls: ['./our-blog-tags.component.css']
})
export class OurBlogTagsComponent implements OnInit {
  displayedColumns = ['id', 'name', 'actions'];
  dataSource = [];

  constructor(
    private readonly blogTagService: BlogTagService
  ) { }

  ngOnInit() {
    this.fetchTags();
  }

  fetchTags() {
    this.blogTagService.getBlogTags().subscribe(d => this.dataSource = d);
  }

  onCloseCreate(refetch) {
    if (refetch) {
      this.fetchTags();
    }
  }
}
