<div class="global-container" id="global-container-id">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="emulatingSection = 'Intro'">Intro principal</button> &nbsp;
        <button (click)="displayOpenPositionsPage()">Posiciones abiertas</button> &nbsp;
        <button (click)="displayJobRequestsPage()">Solicitudes de trabajo</button> &nbsp;
        <button (click)="displayReferralsPage()">Candidatos referidos</button> &nbsp;
    </div> <br> <br> <br>
    <!--PRINCIPAL PAGE-->
    <div *ngIf="emulatingSection == 'Intro'">
        <h4>Introducción principal de Careers page</h4>
        <br>
        <form [formGroup]="introForm" id="introForm" (ngSubmit)="submitPrincipalPageForm()">

            <!-- ENGLISH-->
            <span class="text-success subtitle">Información en Inglés</span>
            <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título</mat-label>
                <input name="titleEng" formControlName="titleEng" #title1Eng matInput maxlength="100">
                <mat-hint align="end">{{title1Eng.value.length}} / 100</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>


            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="introEng" formControlName="introEng"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>


            <!-- SPANISH-->
            <span class="text-success subtitle">Información en Español</span>
            <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título</mat-label>
                <input name="titleEsp" formControlName="titleEsp" #title1Esp matInput maxlength="100">
                <mat-hint align="end">{{title1Esp.value.length}} / 100</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="introEsp" formControlName="introEsp"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

        </form>
        <div class="submit-button-container">
            <button form="introForm" mat-button>Guardar cambios</button>
        </div>
    </div>

    <!-- OPEN POSITIONS -->
    <div *ngIf="emulatingSection == 'Open Positions Page'">
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View Open Positions Table'; actionTitle = 'Posiciones existentes'; submitButtonDirty = false; slashOrUderscore = false;">Ver
        Posiciones abiertas</button> &nbsp;
            <button (click)="displayCreateNewOpenPosition(0, '0', 'Crear Nueva posición')">Crear Posición</button> &nbsp;

            <br> <br>
        </div>
        <h4><strong>{{actionTitle}}</strong></h4>
        <div *ngIf="emulatingSubsection == 'View Open Positions Table'">

            <div *ngIf="loadManatalData">
                <mat-form-field class="filters-container">
                    <mat-label>Filtrar Datos</mat-label>
                    <input matInput (keyup)="applyFilter($event)" placeholder="Empieza una busqueda">
                </mat-form-field>
                <div class="table-responsive">
                    <table id="table-open-position" mat-table [dataSource]="dataOpenPositions" class="mat-elevation-z8 global-table" matSort>

                        <ng-container matColumnDef="imgUrl">
                            <th class="tableCells" mat-header-cell *matHeaderCellDef> </th>
                            <td class="tableCells" mat-cell *matCellDef="let element">
                                <img *ngIf="element.imgUrl !== null" style="width: 100px;" src="{{element.imgUrl}}" alt="">
                                <img class="no-image" *ngIf="element.imgUrl == null" src="http://vnttradingco.ozonedesk.cloud/Inventory/product/uploads/default.png" alt="">
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="title">
                            <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                            <td class="tableCells" mat-cell *matCellDef="let element"> {{element.title}} </td>
                        </ng-container>

                        <ng-container matColumnDef="from">
                            <th class="tableCells" mat-header-cell *matHeaderCellDef> Creado desde </th>
                            <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                            <td class="tableCells" mat-cell *matCellDef="let element"> <img class="manatal-logo" *ngIf="element.idManatal !== 0" src="https://www.manatal.com/wp-content/themes/wordpress_website/img/manatal-img/manatal-logo-text2.png" alt=""> <img class="oceans-logo" *ngIf="element.idManatal === 0"
                                    src="https://res.cloudinary.com/oceans-consulting-firm/image/upload/v1612882702/logos/logo-color_xdip1b.png" alt=""></td>
                        </ng-container>

                        <ng-container matColumnDef="status">
                            <th class="tableCells" mat-header-cell *matHeaderCellDef> Estado </th>
                            <td class="tableCells" mat-cell *matCellDef="let element">
                                <span class="text-success" style="margin-right: -90px;" *ngIf="element.idCareerStatus == 1">Publicado</span>
                                <span class="text-danger" style="margin-right: -90px;" *ngIf="element.idCareerStatus == 2">No
                  Publicado</span>
                                <span matTooltip="Click para editar" (click)="displayCreateNewOpenPosition(element.id, element, 'Editar Posición')" class="text-blue span-btn" style="margin-right: -90px;" *ngIf="element.idCareerStatus == null">Edita
                  para poder publicar</span>
                                <mat-form-field style="width: 110px;" *ngIf="element.idCareerStatus !== null">
                                    <mat-select name="idJobLevel">
                                        <mat-option style="color: red !important;" *ngFor="let status of dataOpenPositionStatuses" [value]="status.idCareerStatus" [ngClass]="{'hidden': status.name == 'Removed' || status.idCareerStatus === element.idCareerStatus}" (click)="changeOpenPositionStatus(element.id, status.idCareerStatus, status.name)">
                                            <span class="text-success" *ngIf="status.name === 'Published' && element.idCareerStatus === 2">Publicado</span>
                                            <span class="text-danger" *ngIf="status.name === 'Draft' && element.idCareerStatus === 1">No
                        Publicado</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="position">
                            <th class="tableCells" mat-header-cell *matHeaderCellDef> Posición </th>
                            <td class="tableCells" mat-cell *matCellDef="let element">
                                <span style="margin-right: -10px;">{{element.position}}</span>
                                <mat-form-field style="width: 30px;" *ngIf="element.position !== null">
                                    <mat-select name="idJobLevel">
                                        <mat-option *ngFor="let openPosition of positions" (click)="changePositionToOpenPosition(element.position, openPosition.position)" [ngClass]="{'hidden': openPosition.position == null}">
                                            <span>Posición: {{openPosition.position}}</span>
                                        </mat-option>

                                    </mat-select>
                                </mat-form-field>
                                <span class="text-danger" *ngIf="element.position === null">Publica para cambiar Posición</span>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="icons">
                            <th class="tableCells" mat-header-cell *matHeaderCellDef> Acciones </th>
                            <td class="tableCells" mat-cell *matCellDef="let element">
                                <a class="button-icons" (click)="displayCreateNewOpenPosition(element.id, element, 'Editar Posición')" matTooltip="Editar">
                                    <mat-icon>edit</mat-icon>
                                </a>
                                <a *ngIf="element.idManatal === 0" class="button-icons" (click)="delete(element.id)" matTooltip="Eliminar">
                                    <mat-icon>delete</mat-icon>
                                </a>
                                <!-- <a class="button-icons" (click)="displayCreateNewOpenPosition(0, element, 'Clonar posición')" matTooltip="Clonar">
                              <mat-icon>file_copy</mat-icon>
                          </a> -->
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>

                    </table>
                </div>
                <mat-paginator id="paginator-op" [pageSizeOptions]="[50, 150, 250, 400]"></mat-paginator>
            </div>

            <div *ngIf="!loadManatalData">
                <div><br> Espera, se están cargando los datos...
                </div>
                <div class="spinner-border m-2" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

        </div>

        <!--CREATE OPEN POSITION-->
        <div *ngIf="emulatingSubsection == 'Creating Open Position'">
            <form style="padding: 5px 20px 5px 20px;" [formGroup]="openPositionsForm" id="openPositionsForm" (ngSubmit)="submitOpenPosition(imgInput)">
                <br>
                <div style="width: 95%;" class="row">
                    <div class="col-sm">
                        <mat-form-field class="example-full-width large-inputs">
                            <mat-label>Título</mat-label>
                            <input name="title" formControlName="title" #titleOpenPositionEng matInput maxlength="100">
                            <mat-hint align="end">{{titleOpenPositionEng.value.length}} / 100</mat-hint>
                            <mat-error>El título es requerido</mat-error>
                        </mat-form-field> <span *ngIf="!editingAtFirst" style="color: orangered;font-size: 14px;"><strong>NOTA:</strong> Ten presente que si editas el título la
              URL también se actualizará y si ya compartiste la URL con algunas personas éstas no podrán acceder a la
              posición.</span>
                        <br>

                        <span class="text-danger" style="float: left;" *ngIf="slashOrUderscore">No se permiten los caracteres / _
              + * & ? % : <> en el título</span><br><br>
                        <div style="text-align: left;">
                            <mat-form-field>
                                <mat-label>Nivel del puesto</mat-label>
                                <mat-select name="idJobLevel" formControlName="idJobLevel">
                                    <mat-option *ngFor="let level of dataJobLevels" [value]="level.idJobLevel">
                                        {{level.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>El nivel es requerido</mat-error>
                            </mat-form-field> &nbsp;

                            <mat-form-field>
                                <mat-label>Jornada laboral</mat-label>
                                <mat-select name="idJobType" formControlName="idJobType">
                                    <mat-option *ngFor="let type of dataJobTypes" [value]="type.idJobType">
                                        {{type.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>La jornada es requerida</mat-error>
                            </mat-form-field> &nbsp;

                            <mat-form-field>
                                <mat-label>Lugar de trabajo</mat-label>
                                <mat-select name="idWorkplace" formControlName="idWorkplace">
                                    <mat-option *ngFor="let workp of dataWorkplaces" [value]="workp.idWorkplace">
                                        {{workp.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error>El lugar es requerido</mat-error>
                            </mat-form-field>
                            <br>
                            <br>
                            <div>
                                <p><strong>Selecciona el color a desplegar</strong></p>
                                <mat-radio-group aria-label="Select an option" formControlName="color">
                                    <mat-radio-button id="yellow-radio-button" style="color: #eeb30f;" value="var(--clr-yellow)">Yellow
                                    </mat-radio-button> &nbsp;
                                    <mat-radio-button id="blue-radio-button" style="color: #058993" value="var(--clr-blueDark)">light blue
                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <div *ngIf="submitButtonDirty">
                                <mat-error *ngIf="openPositionsForm.get('color').hasError('required')">
                                    The english level is required
                                </mat-error>
                            </div> <br>
                            <br>

                            <mat-form-field class="example-full-width large-inputs">
                              <mat-label>Url Form Manatal</mat-label>
                              <input name="urlManatal" formControlName="urlManatal" #urlManatalOpenPositionEng matInput maxlength="100">
                              <mat-hint align="end">{{urlManatalOpenPositionEng.value.length}} / 100</mat-hint>
                          </mat-form-field>
                          <br>
                        </div>
                    </div>
                    <div class="col-sm">
                        <label><strong>Imagen de la posición</strong></label> <br>
                        <img style="width: 280px;" src="{{imgSelected}}" alt="">
                        <div class="input-file-container">
                            <label class="label-file" for="imgFile"><i class="fa fa-upload upload-icon" aria-hidden="true"></i> Choose
                File</label>
                        </div>
                        <input hidden id="imgFile" #imgInput class="input-file" type="file" accept="image/x-png,image/gif,image/jpeg" formControlName="imgFile" (change)="onSelectFile($event)">
                        <div *ngIf="submitButtonDirty">
                            <mat-error *ngIf="openPositionsForm.get('imgFile').hasError('required')">
                                La imagen es requerida
                            </mat-error>
                        </div>
                    </div>

                </div>
                <br>
                <mat-label class="label-text-editor">Requerimientos de la posición</mat-label>
                <ckeditor formControlName="jobRequirements"></ckeditor>
                <br>
                <mat-label class="label-text-editor">Descripción de la posición</mat-label>
                <ckeditor formControlName="jobDescription"></ckeditor>

            </form>


            <div class="submit-button-container">
                <button mat-button (click)="emulatingSubsection = 'View Open Positions Table'; actionTitle = 'Posiciones existentes'; submitButtonDirty = false; cancelCreatingUpdating()">Cancelar</button> &nbsp;
                <button form="openPositionsForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>

    <!-- OPEN POSITIONS REQUESTS -->
    <div *ngIf="emulatingSection == 'Open Job Requests Page'">
        <h4><strong>Solicitudes de trabajo</strong></h4>
        <mat-form-field class="filters-container">
            <mat-label>Filtrar Datos</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Empieza una busqueda">
        </mat-form-field>
        <div class="table-responsive">
            <table id="table-job-requests" mat-table [dataSource]="dataJobRequests" class="mat-elevation-z8 global-table" matSort>

                <ng-container matColumnDef="fullName">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span matTooltip="Ver detalles de la persona" (click)="getJobRequestDetail(element)" class="name-span" data-toggle="modal" data-backdrop="static" data-target="#exampleModalLong">{{element.fullName}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="linkedinUrl">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef> Perfil LinkedIn </th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <a *ngIf="element.linkedinUrl !== null" matTooltip="Visitar perfil de LinkedIn" target="blank" href="{{element.linkedinUrl}}">Ver perfil</a>
                        <span *ngIf="element.linkedinUrl == null">No tiene LinkedIn</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="salaryExpectation">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Expectativa salarial </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> {{element.salaryExpectation}} </td>
                </ng-container>

                <ng-container matColumnDef="openPosition">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Posición </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> {{element.openPosition}} </td>
                </ng-container>

                <ng-container matColumnDef="englishLevel">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Nivel de Inglés </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> {{element.englishLevel}} </td>
                </ng-container>

                <ng-container matColumnDef="country">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> País </th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <span *ngIf="element.country !== 'It was not able to get the location' && element.country !== 'Not found' && element.country !== 'Not Defined yet'">{{element.country}}
            </span>
                        <span class="text-danger" *ngIf="element.country === 'It was not able to get the location' || element.country === 'Not found' || element.country === 'Not Defined yet'">No
              fué posible obtener la ubicación</span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsJobRequests"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsJobRequests; "></tr>

            </table>
        </div>
        <mat-paginator id="paginator-jr" [pageSizeOptions]="[50, 150, 250, 400]"></mat-paginator>
    </div>

    <!-- REFERRALS -->
    <div *ngIf="emulatingSection == 'referrals'">
        <h4><strong>Candidatos referidos</strong></h4>
        <mat-form-field class="filters-container">
            <mat-label>Filtrar Datos</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Empieza una busqueda">
        </mat-form-field>
        <div class="table-responsive">
            <table id="table-referrals" mat-table [dataSource]="dataReferrals" class="mat-elevation-z8 global-table" matSort>

                <ng-container matColumnDef="candidateName">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre del Candidato </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.candidateName}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="candidateEmail">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Correo del Candidato </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.candidateEmail}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="phoneNumber">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Teléfono del Candidato </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.candidatePhoneNumber}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.candidateDescription}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="creationDate">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha creación </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.creationDate | date}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="position">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Posición </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.openPosition}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="referrerName">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Referente </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.referrerName}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="referrerEmail">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header>Correo del Referente </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <span>{{element.referrerEmail}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="resume">
                    <th class="tableCells" mat-header-cell *matHeaderCellDef>Curriculum </th>
                    <td class="tableCells" mat-cell *matCellDef="let element"> <a target="blank" href="{{element.resumeFileUrl}}">Click para ver</a></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsReferrals"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnsReferrals; "></tr>

            </table>
        </div>
        <mat-paginator id="paginator-jr" [pageSizeOptions]="[50, 150, 250, 400]"></mat-paginator>
    </div>
</div>

<!--MODAL-->

<div *ngIf="jobRequest !== undefined" class="modal fade" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{jobRequest.fullName}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
            </div>
            <div class="modal-body">
                <h5>Datos personales</h5>
                <ul>
                    <li><strong>Nombre:</strong> {{jobRequest.fullName}}</li>
                    <li><strong>Correo:</strong> {{jobRequest.email}}</li>
                    <li><strong>Teléfono:</strong> {{jobRequest.phone}}</li>
                    <li><strong>País:</strong> {{jobRequest.country}}</li>
                </ul>
                <br>
                <h5>Detalles del perfil</h5>
                <ul>
                    <li><strong>Puesto: </strong> {{jobRequest.openPosition}}</li>
                    <li><strong>Nivel de Inglés:</strong> {{jobRequest.englishLevel}}</li>
                    <li><strong>Expectativa Salarial: </strong>{{jobRequest.salaryExpectation}}</li>
                </ul>
                <br>
                <h5>Curriculum</h5>
                <div id="pdf-container" [innerHtml]="pdfContainer">

                </div>

                <!-- <embed id="embededPDF" width="100%" height="700px" [src]="jobRequestPdf" type="application/pdf"> -->



            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>
