import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class FooterPageService {
  
  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
   }

   // Principal page
  getFooterPricipalData() {
    return this.http.get(this.url + 'FooterPagesPrincipal/GetFooterPrincipalData', this.httpOptions);
  }
  createUpdateFooterPrincipal(data) {
    return this.http.post(this.url + 'FooterPagesPrincipal/CreateUpdateFooterPagePrincipal', data, this.httpOptions);
  }
}
