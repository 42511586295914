import { Component, OnInit, ViewChild } from '@angular/core';
import { OurBlogPageService } from '../../services/our-blog-page.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar, MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';

@Component({
  selector: 'app-our-blog',
  templateUrl: './our-blog.component.html',
  styleUrls: ['./our-blog.component.css']
})
export class OurBlogComponent implements OnInit {

  displayedColumns: string[] = ['imgBannerMobile', 'bannerTitle', 'tags', 'creationDate', 'status', 'icons'];
  displayedColumnsAuthors: string[] = ['imgAuthor', 'authorName', 'icons'];

  @ViewChild(MatPaginator, { static: false })
  set paginator(value: MatPaginator) {
    if (this.dataBlogs) {
      this.dataBlogs.paginator = value;
    }
    if (this.dataBlogs) {
      this.dataBlogs.paginator = value;
    }
  }
  @ViewChild(MatSort, { static: false })
  set sort(value: MatSort) {
    if (this.dataBlogs) {
      this.dataBlogs.sort = value;
    }
    if (this.dataBlogs) {
      this.dataBlogs.sort = value;
    }
  }

  progressBar: boolean;
  emulatingSection: string = 'Intro';
  actionTitle = 'OCEANS BLOGS';
  createUpdateBlog: boolean;
  createUpdateAuthor: boolean;
  blogsForm: FormGroup;
  authorsForm: FormGroup;
  imgSelectedDesktop: any;
  imgSelectedMobile: any;
  selectedFileDesktop: any;
  selectedFileAuthor: any;
  imgSelectedAuthor: any;
  selectedFileMobile: any;
  submitButtonDirty: boolean;
  blogSubmit: boolean;
  authorSubmit: boolean;
  dataAuthorsDropdown: [];
  selectedTags: { id: number; name: string; }[] = [];
  searchTags: { id: number; name: string; }[] = [];
  filterKeyword = '';

  // Forms
  introForm: FormGroup;
  formData = new FormData()
  formDataAuthors = new FormData()

  // Data From API
  dataPrincipalPage: any;
  dataBlogs: any;
  dataAuthors: any;

  // Others
  dataToSend: any;
  emulatingSubsection: string;
  subsectionSubtitle: string;
  loadBlogs: boolean;
  loadAuthors: boolean;

  constructor(
    private ourBlogService: OurBlogPageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.introForm = this.formBuilder.group({
      titleEng: ['', Validators.required],
      titleEsp: ['', Validators.required],
      IntroEng: ['', Validators.required],
      IntroEsp: ['', Validators.required]
    });

    this.ourBlogService.getOurBlogPricipalData().subscribe((res: any) => {
      this.dataPrincipalPage = res[0];
      console.log(this.dataPrincipalPage);
      if (res.length === 0) {
        this.introForm = this.formBuilder.group({
          titleEng: ['', Validators.required],
          titleEsp: ['', Validators.required],
          IntroEng: ['', Validators.required],
          IntroEsp: ['', Validators.required]
        });
      } else {
        this.introForm = this.formBuilder.group({
          titleEng: [this.dataPrincipalPage.titleEng, Validators.required],
          titleEsp: [this.dataPrincipalPage.titleEsp, Validators.required],
          IntroEng: [this.dataPrincipalPage.introEng, Validators.required],
          IntroEsp: [this.dataPrincipalPage.introEsp, Validators.required]
        });
      }
    });
  }
  applyFilter(keyword: string, tags: { id: number }[]) {
    const keywordFormated = keyword.trim().toLowerCase();
    const tagsIds = tags.map(t => t.id).join(',');

    this.dataBlogs.filter = `${keywordFormated}|${tagsIds}`;

    if (this.dataBlogs.paginator) {
      this.dataBlogs.paginator.firstPage();
    }
  }
  applyFilterAuthors(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataAuthors.filter = filterValue.trim().toLowerCase();
    if (this.dataAuthors.paginator) {
      this.dataAuthors.paginator.firstPage();
    }
  }
  displayBlogs() {
    this.actionTitle = 'OCEANS BLOGS';
    this.emulatingSection = 'blogs';
    this.createUpdateBlog = false;
    this.getBlogsData();

  }
  displayAuthors() {
    this.actionTitle = 'AUTHORS';
    this.emulatingSection = 'authors';
    this.createUpdateAuthor = false;
    this.getAuthorsData();
  }

  displayTags() {
    this.actionTitle = 'TAGS';
    this.emulatingSection = 'tags';
    this.createUpdateAuthor = false;
    this.createUpdateBlog = false;
  }

  getBlogsData() {
    this.ourBlogService.getBlogsDat().subscribe((res: any) => {
      this.dataBlogs = new MatTableDataSource(res);
      this.dataBlogs.filterPredicate = this.filterPredicate.bind(this);
      this.getAuthorsData();
    });
  }
  filterPredicate(item, filter: string) {
    const [keyword, tagsIdsStr] = filter.split('|');
    const tagsIds = tagsIdsStr.length ? tagsIdsStr.split(',').map(idStr => Number(idStr)) : [];
    const hasTags = tagsIds.length > 0;
    const hasKeyword = keyword.length > 0;
    const byKeyword = !hasKeyword || item.bannerTitle.toLowerCase().includes(keyword);
    const byTags = !hasTags || item.tags.some(t => tagsIds.includes(t.id));

    return byKeyword && byTags;
  }
  getAuthorsData() {
    this.ourBlogService.getAuthorsData().subscribe((res: any) => {
      this.loadAuthors = true;
      this.dataAuthorsDropdown = res;
      this.loadBlogs = true;
      this.dataAuthors = new MatTableDataSource(res);
    });
  }
  // Principal Page
  submitPrincipalPageForm() {
    if (this.introForm.valid) {
      this.dataToSend = this.introForm.value;
      this.createUpdatePrincipalPage();
    }
  }
  createUpdatePrincipalPage() {
    this.progressBar = true;
    this.ourBlogService.createUpdateOurBlogPagePrincipal(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  displayCreateNewBlog(id, data, action) {
    this.selectedTags = data.tags;
    this.actionTitle = action;
    this.loadBlogs = false;
    this.createUpdateBlog = true;

    if (id === null || id === 0) {
      this.imgSelectedDesktop = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
      this.imgSelectedMobile = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
      this.blogsForm = this.formBuilder.group({
        idBlog: [0],
        imgBannerDesktop: ['', Validators.required],
        imgBannerMobile: ['', Validators.required],
        bannerTitle: [data.bannerTitle, Validators.required],
        creationDate: [data.creationDate, Validators.required],
        htmlBody: [data.htmlBody, Validators.required],
        idAuthor: [data.idAuthor, Validators.required],
        urlSlug: [
          data.urlSlug,
          [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]+$/), Validators.pattern(/^[^\s]+$/)],
        ]
      });
    } else {
      this.imgSelectedDesktop = data.imgBannerDesktop;
      this.imgSelectedMobile = data.imgBannerMobile;
      this.blogsForm = this.formBuilder.group({
        idBlog: [id],
        imgBannerDesktop: new FormControl(''),
        imgBannerMobile: new FormControl(''),
        bannerTitle: [data.bannerTitle, Validators.required],
        creationDate: [data.creationDate, Validators.required],
        htmlBody: [data.htmlBody, Validators.required],
        idAuthor: [data.idAuthor, Validators.required],
        urlSlug: [
          data.urlSlug,
          [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]+$/), Validators.pattern(/^[^\s]+$/)],
        ]
      });
    }
  }

  displayCreateNewAuthor(id, data, action) {
    this.actionTitle = action;
    this.loadAuthors = false;
    this.createUpdateAuthor = true;

    if (id === null || id === 0) {
      this.imgSelectedAuthor = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
      this.authorsForm = this.formBuilder.group({
        idAuthor: [0],
        authorName: [data.authorName, Validators.required],
        urlAuthorLinkedinProfile: [data.urlAuthorLinkedinProfile, Validators.required],
        imgAuthor: ['', Validators.required],
        authorDescription: [data.authorDescription, Validators.required]
      });
    } else {
      this.imgSelectedAuthor = data.imgAuthor;
      this.authorsForm = this.formBuilder.group({
        idAuthor: [id],
        authorName: new FormControl(data.authorName, Validators.required),
        urlAuthorLinkedinProfile: [data.urlAuthorLinkedinProfile, Validators.required],
        imgAuthor: new FormControl(''),
        authorDescription: [data.authorDescription, Validators.required]
      });
    }
  }
  onSelectFile(fileInput: any, device) {

    if (device === 'desktop') {
      this.selectedFileDesktop = fileInput.target.files[0];
    } else {
      this.selectedFileMobile = fileInput.target.files[0];
    }
    if(device === 'author'){
      this.selectedFileAuthor = fileInput.target.files[0];
    }

    if (fileInput.target.files[0] == undefined) {
      if (device === 'desktop') {
        this.imgSelectedDesktop = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
      } else {
        this.imgSelectedMobile = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
      }
      this.imgSelectedAuthor = 'https://makotopanama.com/wp-content/plugins/smg-theme-tools-master-0d8135f9722217011e2b925af5866b5bffb3bb5a/images/not-available-es.png';
    } else {
      var reader = new FileReader();
      reader.readAsDataURL(fileInput.target.files[0]);
      reader.onload = (_event) => {

        if (device === 'desktop') {
          this.imgSelectedDesktop = reader.result;
        } else {
          this.imgSelectedMobile = reader.result;
        }
        this.imgSelectedAuthor = reader.result;
      }
    }
  }

  submitBlog(imgDesktopInput, imgMobileInput) {
    if (this.actionTitle === 'Crear Nuevo Blog') {
      this.submitButtonDirty = true;
    }
    if (this.blogsForm.valid) {
      this.formData.append('imgBannerDesktop', this.selectedFileDesktop);
      this.formData.append('imgBannerMobile', this.selectedFileMobile);
      this.formData.append('idBlog', this.blogsForm.value.idBlog);
      this.formData.append('bannerTitle', this.blogsForm.value.bannerTitle);
      this.formData.append('idAuthor', this.blogsForm.value.idAuthor);
      this.formData.append('urlSlug', this.blogsForm.value.urlSlug);
      this.formData.append('tagsIds', (this.selectedTags || []).map(st => st.id).join(',') || '');
      var dateToSend;

        const dateSelected = new Date(this.blogsForm.value.creationDate);
         dateToSend = (dateSelected.getMonth() + 1) + '-' + dateSelected.getDate() + '-' + dateSelected.getFullYear();

      this.formData.append('creationDate', dateToSend);
      this.formData.append('htmlBody', this.blogsForm.value.htmlBody);

      this.createUpdateBlogFunc(imgDesktopInput, imgMobileInput);
    } else {
      this.openSnackBar('Todos los campos son requeridos');
    }
  }

  submitAuthor(imgInputAuthor) {
    if (this.actionTitle === 'Crear Nuevo Author') {
      this.submitButtonDirty = true;
    }
    if (this.authorsForm.valid) {
      this.formDataAuthors.append('idAuthor', this.authorsForm.value.idAuthor);
      this.formDataAuthors.append('authorName', this.authorsForm.value.authorName);
      this.formDataAuthors.append('urlAuthorLinkedinProfile', this.authorsForm.value.urlAuthorLinkedinProfile);
      this.formDataAuthors.append('imgAuthor', this.selectedFileAuthor);
      this.formDataAuthors.append('authorDescription', this.authorsForm.value.authorDescription);
      this.createUpdateAuthorFunc(imgInputAuthor);
    } else {
      this.openSnackBar('Todos los campos son requeridos');
    }
  }
  createUpdateAuthorFunc(imgInputAuthor) {
    this.authorSubmit = true;
    this.ourBlogService.createUpdateAuthor(this.formDataAuthors).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.authorSubmit = false;
      this.deleteFormDataAuthors();
      this.authorsForm.reset();
      this.submitButtonDirty = false;
      this.displayAuthors();
      this.selectedFileAuthor = null;
      imgInputAuthor.value = null;
    }, err => {
        this.openSnackBar('Error de servidor');
      this.deleteFormDataAuthors();
    });
  }
  createUpdateBlogFunc(imgDesktopInput, imgMobileInput) {
    this.blogSubmit = true;
    this.ourBlogService.createUpdateBlog(this.formData).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.blogSubmit = false;
      this.deleteFormData();
      this.blogsForm.reset();
      this.submitButtonDirty = false;
      this.displayBlogs();
      this.selectedFileDesktop = null;
      this.selectedFileMobile = null;
      imgDesktopInput.value = null;
      imgMobileInput.value = null;
    }, err => {
        this.openSnackBar('Error de servidor');
      this.deleteFormData();
    });
  }
  deleteFormDataAuthors() {
    this.formDataAuthors.delete('idAuthor');
    this.formDataAuthors.delete('authorName');
    this.formDataAuthors.delete('urlAuthorLinkedinProfile');
    this.formDataAuthors.delete('imgAuthor');
    this.formDataAuthors.delete('urlSlug');
    this.formDataAuthors.delete('authorDescription');
  }
  deleteFormData() {
    this.formData.delete('imgBannerDesktop');
    this.formData.delete('imgBannerMobile');
    this.formData.delete('idBlog');
    this.formData.delete('bannerTitle');
    this.formData.delete('creationDate');
    this.formData.delete('htmlBody');
    this.formData.delete('idAuthor');
    this.formData.delete('urlSlug');
    this.formData.delete('tagsIds');
    this.selectedTags = [];
  }

  delete(id) {
    this.openConfirmationMessageDelete(id);
  }
  deleteAuthor(id) {
    this.openConfirmationMessageDeleteAuthor(id);
  }
  openConfirmationMessageDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Blog', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadBlogs = false;
        // Delete
        this.ourBlogService.deleteBlog(id).subscribe((response) => {
          this.openSnackBar('¡Eliminado correctamente!');
          this.getBlogsData();
        }, err => {
          this.openSnackBar('¡Error de servidor!');
        });
      }
    });
  }
  openConfirmationMessageDeleteAuthor(id): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Autor', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadAuthors = false;
        // Delete
        this.ourBlogService.deleteAuthor(id).subscribe((response) => {
          this.openSnackBar('¡Eliminado correctamente!');
          this.getAuthorsData();
        }, err => {
          this.openSnackBar('¡Error de servidor!');
        });
      }
    });
  }
  publishUnpublisBlog(id, status){
    console.log(status);
    this.ourBlogService.publishUnpublishBlog(id, status).subscribe((res: any) => {
      if(status){
        this.openSnackBar('Se publicó con exito');
      } else {
        this.openSnackBar('Se despublicó con exito');
      }

      this.displayBlogs();
    }, err => {
        this.openSnackBar('Error de servidor');
    });
  }
  // Others

  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }

  onTagChange(tags) {
    this.selectedTags = tags;
  }

  // onTagSearch(tags) {
  //   this.searchTags = tags;
  //   this.applyFilter(this.filterKeyword);
  // }

  mapTags(tags: { name: string }[]) {
    return tags.map(x => x.name).join(', ');
  }
}
