import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { OurBlogConfirmDeleteComponent } from '../our-blog-confirm-delete/our-blog-confirm-delete.component';

@Component({
  selector: 'app-our-blog-delete-button',
  templateUrl: './our-blog-delete-button.component.html'
})
export class OurBlogDeleteButtonComponent {
  constructor(
    private readonly dialog: MatDialog
  ) {}

  @Input() tag = null
  @Output() onCloseEvent = new EventEmitter<string>();

  openDialog() {
    const dialogRef = this.dialog.open(OurBlogConfirmDeleteComponent, {
      data: {
        tag: this.tag
      }
    });

    dialogRef.afterClosed().subscribe(refetch => {
      this.onCloseEvent.emit(refetch);
    });
  }
}
