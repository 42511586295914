import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { ROUTING, ROUTINGPROVIDERS } from './app.routing';
import { HomeComponent } from './components/home/home.component';
import { LoggedinGuard } from './services/loggedin.guard';
import { UserService } from './services/user.service';
import { RouterModule } from '@angular/router';
import { SidenavComponent } from './components/shared/sidenav/sidenav.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoggedoutGuard } from './services/loggedout.guard';
import { OceansAcademyComponent } from './components/oceans-academy/oceans-academy.component';
import { SnackbarComponent } from './components/shared/snackbar/snackbar.component';
import { ConfirmationMessageComponent } from './components/shared/confirmation-message/confirmation-message.component';
import { ServicesComponent } from './components/services/services.component';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material';
import { CareersComponent } from './components/careers/careers.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { ContactComponent } from './components/contact/contact.component';
import { FooterPageComponent } from './components/footer-page/footer-page.component';
import { WebinarsComponent } from './components/webinars/webinars.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { SafeHtmlPipeComponent } from './components/helpers/safe-html-pipe/safe-html-pipe.component';

import { OurBlogModule } from './components/our-blog/our-blog.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SidenavComponent,
    OceansAcademyComponent,
    SnackbarComponent,
    ConfirmationMessageComponent,
    ServicesComponent,
    CareersComponent,
    AboutUsComponent,
    ContactComponent,
    FooterPageComponent,
    WebinarsComponent,
    SafeHtmlPipeComponent,
  ],
  entryComponents: [
    SnackbarComponent,
    ConfirmationMessageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ROUTING,
    RouterModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxSpinnerModule,
    HttpClientModule,
    CKEditorModule,
    OurBlogModule
  ],
  providers: [
    ROUTINGPROVIDERS,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    LoggedinGuard,
    UserService,
    LoggedoutGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
