<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="displayWebinarsPage()">Webinars</button> &nbsp;
    </div> <br> <br> <br>

    <!-- WEBINARS -->
    <div *ngIf="emulatingSection == 'Webinars'">
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View webinars Table'; actionTitle = 'Webinars Existentes';">Ver webinars existentes</button> &nbsp;
            <button (click)="displayCreateNewWebinar('0', '0', 'Crear Nuevo Webinar')">Crear Webinar</button> &nbsp;

            <br> <br>
        </div>
        <h4><strong>{{actionTitle}}</strong></h4>
        <div *ngIf="emulatingSubsection == 'View webinars Table'">
            <table mat-table [dataSource]="dataWebinars" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Posición Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Posición Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.idWebinar)" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayCreateNewWebinar(element.idWebinar, element, 'Editar Webinar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>


        <!--CREATE WEBINAR-->
        <div *ngIf="emulatingSubsection == 'Creating Webinar'">

            <form [formGroup]="webinarForm" id="webinarForm" (ngSubmit)="submitWebinar()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #titleEng matInput maxlength="300">
                    <mat-hint align="end">{{titleEng.value.length}} / 300</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Fecha y hora</mat-label>
                    <input name="dateEng" formControlName="dateEng" #dateWEng matInput maxlength="500">
                    <mat-hint align="end">{{dateWEng.value.length}} / 500</mat-hint>
                    <mat-error>La fecha y la hora son requeridos</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción </mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción es requerida</mat-error>
                </mat-form-field> <br>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Invitación de la reunion </mat-label>
                    <textarea class="height-inputs" matInput name="meetingUrl" formControlName="meetingUrl"></textarea>
                    <mat-error>La invitación es requerida</mat-error>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle ">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs ">
                    <mat-label>Título</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #titleEsp matInput maxlength="300 ">
                    <mat-hint align="end">{{titleEsp.value.length}} / 300</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Fecha y hora</mat-label>
                    <input name="dateEsp" formControlName="dateEsp" #dateWEsp matInput maxlength="500">
                    <mat-hint align="end">{{dateWEsp.value.length}} / 500</mat-hint>
                    <mat-error>La fecha y la hora son requeridos</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción </mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción es requerida</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container ">
                <button form="webinarForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>


</div>