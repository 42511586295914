import { Component, OnInit, ɵConsole } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactPageService } from '../../services/contact-page.service';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  displayedColumns: string[] = ['column1', 'icons'];

  // Data
  dataAddress: any;
  dataPhones: any;
  dataEmails: any;
  dataToSend: any;
  dataPrincipalPage: any;


  // Forms
  whatAreULookingForForm: FormGroup;
  addressesForm: FormGroup;
  phonesForm: FormGroup;
  emailsForm: FormGroup;

  // Booleans
  progressBar: boolean;

  // Strings pages
  emulatingSection: string = 'Principal Page';
  emulatingSubsection: string = 'Addresses Table';
  actionTitle: string = 'Página principal de Contact';

  constructor(
    private contactPageService: ContactPageService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.whatAreULookingForForm = this.formBuilder.group({
      FirstSectionTitleEng: ['', Validators.required],
      FirstSectionTitleEsp: ['', Validators.required],
      FirstItemTitleEng: ['', Validators.required],
      FirtItemDescriptionEng: ['', Validators.required],
      FirstItemTitleEsp: ['', Validators.required],
      FirtItemDescriptionEsp: ['', Validators.required],
      SecondItemTitleEng: ['', Validators.required],
      SecondItemDescriptionEng: ['', Validators.required],
      SecondItemTitleEsp: ['', Validators.required],
      SecondItemDescriptionEsp: ['', Validators.required],
      ThirdItemTitleEng: ['', Validators.required],
      ThirdItemDescriptionEng: ['', Validators.required],
      ThirdItemTitleEsp: ['', Validators.required],
      ThirdItemDescriptionEsp: ['', Validators.required]
    });

    this.contactPageService.getContactPricipalData().subscribe((res: any) => {
      this.dataPrincipalPage = res[0];
      console.log(this.dataPrincipalPage);
      if (res.length === 0) {
        this.whatAreULookingForForm = this.formBuilder.group({
          FirstSectionTitleEng: ['', Validators.required],
          FirstSectionTitleEsp: ['', Validators.required],
          FirstItemTitleEng: ['', Validators.required],
          FirtItemDescriptionEng: ['', Validators.required],
          FirstItemTitleEsp: ['', Validators.required],
          FirtItemDescriptionEsp: ['', Validators.required],
          SecondItemTitleEng: ['', Validators.required],
          SecondItemDescriptionEng: ['', Validators.required],
          SecondItemTitleEsp: ['', Validators.required],
          SecondItemDescriptionEsp: ['', Validators.required],
          ThirdItemTitleEng: ['', Validators.required],
          ThirdItemDescriptionEng: ['', Validators.required],
          ThirdItemTitleEsp: ['', Validators.required],
          ThirdItemDescriptionEsp: ['', Validators.required]
        });
      } else {
        this.whatAreULookingForForm = this.formBuilder.group({
          FirstSectionTitleEng: [this.dataPrincipalPage.firstSectionTitleEng, Validators.required],
          FirstSectionTitleEsp: [this.dataPrincipalPage.firstSectionTitleEsp, Validators.required],
          FirstItemTitleEng: [this.dataPrincipalPage.firstItemTitleEng, Validators.required],
          FirtItemDescriptionEng: [this.dataPrincipalPage.firtItemDescriptionEng, Validators.required],
          FirstItemTitleEsp: [this.dataPrincipalPage.firstItemTitleEsp, Validators.required],
          FirtItemDescriptionEsp: [this.dataPrincipalPage.firtItemDescriptionEsp, Validators.required],
          SecondItemTitleEng: [this.dataPrincipalPage.secondItemTitleEng, Validators.required],
          SecondItemDescriptionEng: [this.dataPrincipalPage.secondItemDescriptionEng, Validators.required],
          SecondItemTitleEsp: [this.dataPrincipalPage.secondItemTitleEsp, Validators.required],
          SecondItemDescriptionEsp: [this.dataPrincipalPage.secondItemDescriptionEsp, Validators.required],
          ThirdItemTitleEng: [this.dataPrincipalPage.thirdItemTitleEng, Validators.required],
          ThirdItemDescriptionEng: [this.dataPrincipalPage.thirdItemDescriptionEng, Validators.required],
          ThirdItemTitleEsp: [this.dataPrincipalPage.thirdItemTitleEsp, Validators.required],
          ThirdItemDescriptionEsp: [this.dataPrincipalPage.thirdItemDescriptionEsp, Validators.required],
        });
      }
    });
  }

  // PRINCIPAL PAGE
  displayPrincipalPage() {
    this.emulatingSection = 'Principal Page';
  }
  submitPrincipalPageForm() {
    if (this.whatAreULookingForForm.valid) {
      this.dataToSend = this.whatAreULookingForForm.value;
      this.createUpdatePrincipalPage();
    }
  }
  createUpdatePrincipalPage() {
    this.progressBar = true;
    this.contactPageService.createUpdateContactPagePrincipal(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Contact footer

  getAddressesData() {
    this.contactPageService.getAddressesData().subscribe((res: any) => {
      this.dataAddress = res;
    });
  }
  displayContactFooter() {
    this.emulatingSection = 'Contact Footer';
    this.emulatingSubsection = 'Addresses Table';
    this.actionTitle = 'Información existente Contact Footer';
    this.getAddressesData();
    this.getPhonesData();
    this.getEmailsData();
  }


  displayCreateAddress(id, data, action) {
    this.actionTitle = action;
    this.emulatingSubsection = 'Create Update Address';
    this.addressesForm = this.formBuilder.group({
      id: [id],
      Address: [data.address, Validators.required]
    });
  }
  submitAddress() {
    if (this.addressesForm.valid) {
      this.dataToSend = this.addressesForm.value;
      this.createUpdateAddress();
    }
  }

  createUpdateAddress() {
    this.progressBar = true;
    this.contactPageService.createUpdateAddress(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'Addresses Table';
      this.actionTitle = 'Información existente Contact Footer';
      this.progressBar = false;
      this.getAddressesData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Phones section

  getPhonesData() {
    this.contactPageService.getPhonesData().subscribe((res: any) => {
      this.dataPhones = res;
    });
  }

  displayCreatePhone(id, data, action) {
    this.actionTitle = action;
    this.emulatingSubsection = 'Create Update Phone';
    this.phonesForm = this.formBuilder.group({
      id: [id],
      PhoneNumber: [data.phoneNumber, Validators.required]
    });
  }

  submitPhone() {
    if (this.phonesForm.valid) {
      this.dataToSend = this.phonesForm.value;
      console.log(this.dataToSend);
      this.createUpdatePhone();
    }
  }
  createUpdatePhone() {
    this.progressBar = true;
    this.contactPageService.createUpdatePhone(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'Addresses Table';
      this.actionTitle = 'Información existente Contact Footer';
      this.progressBar = false;
      this.getPhonesData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Emails section

  getEmailsData() {
    this.contactPageService.getEmailsData().subscribe((res: any) => {
      this.dataEmails = res;
    });
  }

  displayCreateEmail(id, data, action) {
    this.actionTitle = action;
    this.emulatingSubsection = 'Create Update Email';
    this.emailsForm = this.formBuilder.group({
      id: [id],
      email: [data.email, Validators.required]
    });
  }

  submitEmail() {
    if (this.emailsForm.valid) {
      this.dataToSend = this.emailsForm.value;
      console.log(this.dataToSend);
      this.createUpdateEmail();
    }
  }
  createUpdateEmail() {
    this.progressBar = true;
    this.contactPageService.createUpdateEmail(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'Addresses Table';
      this.actionTitle = 'Información existente Contact Footer';
      this.progressBar = false;
      this.getEmailsData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  delete(id, from) {
    this.openConfirmationMessageDelete(id, from);
  }
  openConfirmationMessageDelete(id, from): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Elemento', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete
        if (from === 'Address') {
          this.contactPageService.deleteAddress(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getAddressesData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        } else if (from === 'Phone') {
          this.contactPageService.deletePhone(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getPhonesData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        } else if (from === 'Email') {
          this.contactPageService.deleteEmail(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getEmailsData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        }
      }
    });
  }
  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }

}
