<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="emulatingSection = 'Intro'">Intro principal</button> &nbsp;
        <!--  <button (click)="displaySuccessStoriesTable()">Success Stories</button> &nbsp; -->
    </div> <br> <br> <br>
    <!--PRINCIPAL PAGE-->
    <div *ngIf="emulatingSection == 'Intro'">
        <h4>Footer page</h4>
        <br>
        <form [formGroup]="introForm" id="introForm" (ngSubmit)="submitPrincipalPageForm()">
            <h5>Políticas de privacidad</h5>

            <!-- ENGLISH-->
            <span class="text-success subtitle">Información en Inglés</span>
            <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Privacy Policy </mat-label>
                <textarea class="big-textarea" matInput name="privacyPolicyEng" formControlName="privacyPolicyEng"></textarea>
                <mat-error>The text is required</mat-error>
            </mat-form-field> <br>


            <!-- SPANISH-->
            <span class="text-success subtitle">Información en Español</span>
            <br>
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Políticas de privacidad</mat-label>
                <textarea class="big-textarea" matInput name="privacyPolicyEsp" formControlName="privacyPolicyEsp"></textarea>
                <mat-error>El texto es requerido</mat-error>
            </mat-form-field> <br>

        </form>
        <div class="submit-button-container">
            <button form="introForm" mat-button>Guardar cambios</button>
        </div>
    </div>


</div>