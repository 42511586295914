import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { FooterPageService } from '../../services/footer-page.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';

@Component({
  selector: 'app-footer-page',
  templateUrl: './footer-page.component.html',
  styleUrls: ['./footer-page.component.css']
})
export class FooterPageComponent implements OnInit {

  progressBar: boolean;
  emulatingSection: string = 'Intro';

    // Forms
    introForm: FormGroup;

      // Data From API
  dataPrincipalPage: any;

     // Others
  dataToSend: any;
  emulatingSubsection: string;
  subsectionSubtitle: string;
  constructor(
    private footerPageService: FooterPageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.introForm = this.formBuilder.group({
      privacyPolicyEng: ['', Validators.required],
      privacyPolicyEsp: ['', Validators.required]
    });

    this.footerPageService.getFooterPricipalData().subscribe((res: any) => {
      this.dataPrincipalPage = res[0];
      console.log(this.dataPrincipalPage);
      if (res.length === 0) {
        this.introForm = this.formBuilder.group({
          privacyPolicyEng: ['', Validators.required],
          privacyPolicyEsp: ['', Validators.required]
        });
      } else {
        this.introForm = this.formBuilder.group({
          privacyPolicyEng: [this.dataPrincipalPage.privacyPolicyEng, Validators.required],
          privacyPolicyEsp: [this.dataPrincipalPage.privacyPolicyEsp, Validators.required]
        });
      }
    });
  }

  // Principal Page
  submitPrincipalPageForm() {
    if (this.introForm.valid) {
      this.dataToSend = this.introForm.value;
      this.createUpdatePrincipalPage();
    }
  }

  createUpdatePrincipalPage() {
    this.progressBar = true;
    this.footerPageService.createUpdateFooterPrincipal(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Others

  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }


}
