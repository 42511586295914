import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class WebinarsPageService {

  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token
    });
    this.httpOptions = {
      headers: this.headersObject,
    };
  }

  // Webinars
  getWebinarsData() {
    return this.http.get(this.url + 'Webinars/GetWebinarsData', this.httpOptions);
  }

  createUpdateWebinar(data) {
    return this.http.post(this.url + 'Webinars/CreateUpdateWebinar', data, this.httpOptions);
  }
  deleteWebinar(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'Webinars/DeleteWebinar', id, httpOptions);
  }
}
