import { Component, OnInit } from '@angular/core';
import { AboutUsPageService } from '../../services/about-us-page.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { OurServicesPageService } from '../../services/our-services-page.service';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {

  displayedColumns: string[] = ['titleEng', 'titleEsp', 'icons'];

  progressBar: boolean;
  emulatingSection: string = 'Intro';

  // Forms
  introForm: FormGroup;
  ourValuesForm: FormGroup;
  industryForm: FormGroup;

  // Data From API
  dataPrincipalPage: any;
  dataOurValues: any;
  dataIndustries: any;

  // Others
  dataToSend: any;
  emulatingSubsection: string = 'View Our Values Table';
  subsectionSubtitle: string;
  actionTitle: string = 'Valores Existentes';


  constructor(
    private aboutUsPageService: AboutUsPageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.introForm = this.formBuilder.group({
      titleEng: ['', Validators.required],
      titleEsp: ['', Validators.required],
      introEng: ['', Validators.required],
      introEsp: ['', Validators.required],
      secondIntroEng: ['', Validators.required],
      secondIntroEsp: ['', Validators.required]
    });

    this.aboutUsPageService.getAboutUsPricipalData().subscribe((res: any) => {
      this.dataPrincipalPage = res[0];
      console.log(this.dataPrincipalPage);
      if (res.length === 0) {
        this.introForm = this.formBuilder.group({
          titleEng: ['', Validators.required],
          titleEsp: ['', Validators.required],
          introEng: ['', Validators.required],
          introEsp: ['', Validators.required],
          secondIntroEng: ['', Validators.required],
          secondIntroEsp: ['', Validators.required]
        });
      } else {
        this.introForm = this.formBuilder.group({
          titleEng: [this.dataPrincipalPage.titleEng, Validators.required],
          titleEsp: [this.dataPrincipalPage.titleEsp, Validators.required],
          introEng: [this.dataPrincipalPage.introEng, Validators.required],
          introEsp: [this.dataPrincipalPage.introEsp, Validators.required],
          secondIntroEng: [this.dataPrincipalPage.secondIntroEng, Validators.required],
          secondIntroEsp: [this.dataPrincipalPage.secondIntroEsp, Validators.required]
        });
      }
    });
  }

  // Principal Page
  submitPrincipalPageForm() {
    if (this.introForm.valid) {
      this.dataToSend = this.introForm.value;
      this.createUpdatePrincipalPage();
    }
  }

  createUpdatePrincipalPage() {
    this.progressBar = true;
    this.aboutUsPageService.createUpdateAboutUsPagePrincipal(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Industries
  getIndustriesData() {
    this.aboutUsPageService.getIndustriesData().subscribe((res: any) => {
      this.dataIndustries = res;
    });
  }
  submitIndustry() {
    if (this.industryForm.valid) {
      this.dataToSend = this.industryForm.value;
      this.createUpdateIndustry();
    }
  }
  createUpdateIndustry() {
    this.progressBar = true;
    this.aboutUsPageService.createUpdateIndustry(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'View industry Table';
      this.actionTitle = 'Valores Existentes';
      this.progressBar = false;
      this.getIndustriesData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  displayCreateNewIndustry(id, data, action) {
    this.actionTitle = action;
    this.emulatingSubsection = 'Creating Industry';
    this.industryForm = this.formBuilder.group({
      id: [id],
      titleEng: [data.titleEng, Validators.required],
      titleEsp: [data.titleEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      imgUrl: [data.imgUrl, Validators.required],
      imgUrlMobile: [data.imgUrlMobile, Validators.required]
    });
  }

  // Our values
  getOurValuesData() {
    this.aboutUsPageService.getOurValuesData().subscribe((res: any) => {
      this.dataOurValues = res;
    });
  }
  submitOurValue() {
    if (this.ourValuesForm.valid) {
      this.dataToSend = this.ourValuesForm.value;
      this.createUpdateOurValues();
    }
  }
  createUpdateOurValues() {
    this.progressBar = true;
    this.aboutUsPageService.createUpdateOurValue(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'View Our Values Table';
      this.actionTitle = 'Valores Existentes';
      this.progressBar = false;
      this.getOurValuesData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  displayCreateNewOurValue(id, data, action) {
    this.actionTitle = action;
    this.emulatingSubsection = 'Creating Value';
    this.ourValuesForm = this.formBuilder.group({
      id: [id],
      titleEng: [data.titleEng, Validators.required],
      titleEsp: [data.titleEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      imgUrl: [data.imgUrl, Validators.required],
      imgUrlMobile: [data.imgUrlMobile, Validators.required]
    });
  }


  // Others

  delete(id, from) {
    this.openConfirmationMessageDelete(id, from);
  }

  openConfirmationMessageDelete(id, from): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Elemento', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete
        if (from === 'Our Values') {
          this.aboutUsPageService.deleteValue(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getOurValuesData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        } else if (from === 'Industry') {
          this.aboutUsPageService.deleteIndustry(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getIndustriesData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        }
      }
    });
  }

  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }


}
