<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="emulatingSection = 'Intro'">Intro principal</button> &nbsp;
        <button (click)="displaySuccessStoriesTable()">Success Stories</button> &nbsp;
        <button (click)="displayWhyCrTable()">Why CR</button> &nbsp;
        <button (click)="displayOurTeamTable()">Our Team</button> &nbsp;
        <button (click)="displayWhatClientsSayTable()">What Clients Say</button> &nbsp;


    </div> <br> <br> <br>
    <!--PRINCIPAL PAGE-->
    <div *ngIf="emulatingSection == 'Intro'">
        <h4>Introducción principal de Home page</h4>
        <br>
        <form [formGroup]="introForm" id="introForm" (ngSubmit)="submitPrincipalPageForm()">

            <!-- ENGLISH-->
            <span class="text-success subtitle">Información en Inglés</span>
            <br>
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="IntroEng" formControlName="IntroEng"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>


            <!-- SPANISH-->
            <span class="text-success subtitle">Información en Español</span>
            <br>
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="IntroEsp" formControlName="IntroEsp"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

        </form>
        <div class="submit-button-container">
            <button form="introForm" mat-button>Guardar cambios</button>
        </div>
    </div>

    <!--WHY COSTA RICA-->
    <div *ngIf="emulatingSection == 'Why CR'">
        <h4>Why Costa Rica</h4>
        <br>
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View table'; subsectionSubtitle = 'Datos Existentes'">Ver existentes</button> &nbsp;
            <button (click)="emulatingSubsection = 'Create new Why Cr'; subsectionSubtitle = 'Crear Nuevo'" (click)="displayCreateNewWhyCr('0', '0', 'Crear')">Crear Nuevo</button> &nbsp;

        </div>
        <h4> {{subsectionSubtitle}}</h4>
        <div *ngIf="emulatingSubsection == 'View table'">
            <table mat-table [dataSource]="dataWhyCr" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Título Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Título Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'Why Cr')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayUpdateWhyCr(element.id, element, 'Editar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div *ngIf="emulatingSubsection == 'Create new Why Cr'">
            <form [formGroup]="whyCrForm" id="whyCrForm" (ngSubmit)="submitWhyCr()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #title1Eng matInput maxlength="100">
                    <mat-hint align="end">{{title1Eng.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url imagen del Banner para desktop</mat-label>
                    <input name="imgBannerUrl" formControlName="imgBannerUrl" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url imagen del Banner para mobile</mat-label>
                    <input name="imgBannerUrlMobile" formControlName="imgBannerUrlMobile" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url del logo</mat-label>
                    <input name="imgLogoUrl" formControlName="imgLogoUrl" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Primer descripción grande</mat-label>
                    <textarea class="height-inputs" matInput name="bigDescriptionEng" formControlName="bigDescriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Titulo 1er item</mat-label>
                    <input name="firstItemTitle" formControlName="firstItemTitle" matInput>
                    <mat-error>Titulo es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url imagen del 1er item</mat-label>
                    <input name="imgFirstItem" formControlName="imgFirstItem" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción 1er item</mat-label>
                    <textarea matInput name="firstItemDescription" formControlName="firstItemDescription"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Titulo 2do item</mat-label>
                    <input name="secondItemTitle" formControlName="secondItemTitle" matInput>
                    <mat-error>Titulo es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url imagen del 2do item</mat-label>
                    <input name="imgSecondItem" formControlName="imgSecondItem" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción 2do item</mat-label>
                    <textarea matInput name="secondItemDescription" formControlName="secondItemDescription"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Titulo 3er item</mat-label>
                    <input name="thirdItemTitle" formControlName="thirdItemTitle" matInput>
                    <mat-error>Titulo es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url imagen del 3er item</mat-label>
                    <input name="imgThirdItem" formControlName="imgThirdItem" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción 3er item</mat-label>
                    <textarea matInput name="thirdItemDescription" formControlName="thirdItemDescription"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen al lado de descripción para desktop</mat-label>
                    <input name="imgSecondBigDescUrl" formControlName="imgSecondBigDescUrl" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen al lado de descripción para mobile</mat-label>
                    <input name="imgSecondBigDescUrlMobile" formControlName="imgSecondBigDescUrlMobile" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Segunda descripción grande</mat-label>
                    <textarea class="height-inputs" matInput name="secondBigDescription" formControlName="secondBigDescription"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Tercer descripción</mat-label>
                    <textarea class="height-inputs" matInput name="thirdBigDescription" formControlName="thirdBigDescription"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Texto promo bar</mat-label>
                    <input name="promoBarDescription" formControlName="promoBarDescription" #promoBarDescription matInput maxlength="180">
                    <mat-hint align="end">{{promoBarDescription.value.length}} / 180</mat-hint>
                    <mat-error>El texto es requerido</mat-error>
                </mat-form-field> <br>
                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #title1Esp matInput maxlength="100">
                    <mat-hint align="end">{{title1Esp.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción en el blog grande</mat-label>
                    <textarea class="height-inputs" matInput name="bigDescriptionEsp" formControlName="bigDescriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button form="whyCrForm" mat-button>Guardar cambios</button>
            </div>
        </div>

    </div>

    <!-- OUR TEAM -->
    <div *ngIf="emulatingSection == 'Our Team'">
        <h4>Our Team</h4>
        <br>
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View table'; subsectionSubtitle = 'Datos Existentes'">Ver existentes</button> &nbsp;
            <button (click)="emulatingSubsection = 'Create new Our Team'; subsectionSubtitle = 'Crear Nuevo'" (click)="displayCreateOurTeam('0', '0', 'Crear')">Crear Nuevo</button> &nbsp;

        </div>
        <h4> {{subsectionSubtitle}}</h4>
        <div *ngIf="emulatingSubsection == 'View table'">
            <table mat-table [dataSource]="dataOurTeam" class="mat-elevation-z8">

                <ng-container matColumnDef="teamMemberName">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Nombre miembro del equipo </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.teamMemberName}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'Our Team')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayUpdateOurTeam(element.id, element, 'Editar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsOurTeam"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumnsOurTeam;"></tr>
            </table>
        </div>

        <div *ngIf="emulatingSubsection == 'Create new Our Team'">
            <form [formGroup]="ourTeamForm" id="ourTeamForm" (ngSubmit)="submitOurTeam()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Nombre miembro del equipo</mat-label>
                    <input name="teamMemberName" formControlName="teamMemberName" #title1Eng matInput maxlength="100">
                    <mat-hint align="end">{{title1Eng.value.length}} / 100</mat-hint>
                    <mat-error>El nombre es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Profesión o cargo</mat-label>
                    <input name="positionEng" formControlName="positionEng" #positionEng matInput maxlength="100">
                    <mat-hint align="end">{{positionEng.value.length}} / 100</mat-hint>
                    <mat-error>La profesion es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>URL de la imagen</mat-label>
                    <input name="urlImg" formControlName="urlImg" matInput>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Profesión o cargo</mat-label>
                    <input name="positionEsp" formControlName="positionEsp" #positionEsp matInput maxlength="100">
                    <mat-hint align="end">{{positionEsp.value.length}} / 100</mat-hint>
                    <mat-error>La profesion es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button form="ourTeamForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>

    <!-- WHAT CLIENTS SAY -->
    <div *ngIf="emulatingSection == 'What Clients Say'">
        <h4>What Clients Say</h4>
        <br>
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View table'; subsectionSubtitle = 'Datos Existentes'">Ver existentes</button> &nbsp;
            <button (click)="emulatingSubsection = 'Create new What Clients Say'; subsectionSubtitle = 'Crear Nuevo'" (click)="displayCreateWhatClientsSay
            ('0', '0', 'Crear')">Crear Nuevo</button> &nbsp;

        </div>
        <h4> {{subsectionSubtitle}}</h4>
        <div *ngIf="emulatingSubsection == 'View table'">
            <table mat-table [dataSource]="dataWhatClientsSay" class="mat-elevation-z8">

                <ng-container matColumnDef="referenceEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Referencia Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.referenceEng}} </td>
                </ng-container>

                <ng-container matColumnDef="referenceEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Referencia Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.referenceEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'What Clients Say')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayUpdateWhatClientsSay(element.id, element, 'Editar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsWhatClientsSay"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumnsWhatClientsSay;"></tr>
            </table>
        </div>

        <div *ngIf="emulatingSubsection == 'Create new What Clients Say'">
            <form [formGroup]="whatClientsSayForm" id="whatClientsSayForm" (ngSubmit)="submitWhatClientsSay()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Referencia</mat-label>
                    <input name="referenceEng" formControlName="referenceEng" #referenceEng matInput maxlength="300">
                    <mat-hint align="end">{{referenceEng.value.length}} / 300</mat-hint>
                    <mat-error>El nombre de referencia es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Lugar</mat-label>
                    <input name="place" formControlName="place" #place matInput maxlength="400">
                    <mat-hint align="end">{{place.value.length}} / 400</mat-hint>
                    <mat-error>El lugar es requerido</mat-error>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Referencia</mat-label>
                    <input name="referenceEsp" formControlName="referenceEsp" #referenceEsp matInput maxlength="300">
                    <mat-hint align="end">{{referenceEsp.value.length}} / 300</mat-hint>
                    <mat-error>El nombre de referencia es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button form="whatClientsSayForm" mat-button>Guardar cambios</button>
            </div>
        </div>

    </div>

    <!-- SUCCESS STORIES -->
    <div *ngIf="emulatingSection == 'Success Stories'">
        <h4>Success Stories</h4>
        <br>
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View table'; subsectionSubtitle = 'Datos Existentes'">Ver existentes</button> &nbsp;
            <button (click)="emulatingSubsection = 'Create new Success Stories'; subsectionSubtitle = 'Crear Nuevo'" (click)="displayCreateSuccessStories
            ('0', '0', 'Crear')">Crear Nuevo</button> &nbsp;

        </div>
        <h4> {{subsectionSubtitle}}</h4>
        <div *ngIf="emulatingSubsection == 'View table'">
            <table mat-table [dataSource]="dataSuccessStories" class="mat-elevation-z8">

                <ng-container matColumnDef="storyTitleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Título Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.storyTitleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="storyTitleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Título Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.storyTitleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.idStory, 'Success Stories')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayUpdateSuccessStories(element.idStory, element, 'Editar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumnsSuccessStories"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumnsSuccessStories;"></tr>
            </table>
        </div>

        <div *ngIf="emulatingSubsection == 'Create new Success Stories'">
            <form [formGroup]="successStoriesForm" id="successStoriesForm" (ngSubmit)="submitSuccessStory()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="storyTitleEng" formControlName="storyTitleEng" #storyTitleEng matInput maxlength="200">
                    <mat-hint align="end">{{storyTitleEng.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="storyDescriptionEng" formControlName="storyDescriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="storyTitleEsp" formControlName="storyTitleEsp" #storyTitleEsp matInput maxlength="200">
                    <mat-hint align="end">{{storyTitleEsp.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="storyDescriptionEsp" formControlName="storyDescriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

            </form>
            <div class="submit-button-container">
                <button form="successStoriesForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>
</div>