import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { BlogTagService } from '../../../services/blog-tag.service';

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'our-blog-create-dialog.component.html',
})
export class OurBlogCreateDialogComponent {
  form: FormGroup;

  constructor(
    private readonly dialogRef: MatDialogRef<OurBlogCreateDialogComponent>,
    private readonly fb: FormBuilder,
    private readonly blogTagService: BlogTagService
  ) {
    this.form = fb.group({
      name: new FormControl('', [ Validators.required ])
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.blogTagService.createBlogTag(this.form.value.name.trim())
        .subscribe(
          () => this.onCancel(true),
          (error) => this.form.controls.name.setErrors({
            unique: error.status === 409,
            unknown: error.status !== 409
          })
        )
    }
  }

  onCancel(refetch = false) {
    this.form.reset();
    this.dialogRef.close(refetch);
  }
}
