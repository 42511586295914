import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class OurBlogPageService {

  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;
  private httpOptionsSendFile;
  private headersObjectAuthorized;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
    this.headersObjectAuthorized = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    this.httpOptionsSendFile = {
      headers: this.headersObjectAuthorized
    };
   }

   // PRINCIPAL PAGE
  getOurBlogPricipalData() {
    return this.http.get(this.url + 'OurBlogPagesPrincipal/GetOurBlogPrincipalData', this.httpOptions);
  }
  createUpdateOurBlogPagePrincipal(data) {
    return this.http.post(this.url + 'OurBlogPagesPrincipal/CreateUpdateOurBlogPagePrincipal', data, this.httpOptions);
  }

  // BLOGS
  getBlogsDat() {
    return this.http.get(this.url + 'OurBlogBlogs/GetBlogsData', this.httpOptions);
  }
  createUpdateBlog(data) {
    return this.http.post(this.url + 'OurBlogBlogs/CreateUpdateBlog', data, this.httpOptionsSendFile);
  }
  deleteBlog(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'OurBlogBlogs/DeleteBlog', id, httpOptions);
  }
  publishUnpublishBlog(id, publishStatus) {
    const httpOptions = {
      params: new HttpParams().set('id', id).set('publishStatus', publishStatus),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'OurBlogBlogs/PublishUnpublishBlog', id, httpOptions);
  }
  // AUTHORS
  getAuthorsData() {
    return this.http.get(this.url + 'BlogAuthors/GetAuthorsData', this.httpOptions);
  }
  createUpdateAuthor(data) {
    return this.http.post(this.url + 'BlogAuthors/CreateUpdateAuthor', data, this.httpOptionsSendFile);
  }
  deleteAuthor(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'BlogAuthors/DeleteAuthor', id, httpOptions);
  }
}
