<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="displayPrincipalPage()">Oceans Academy Principal</button> &nbsp;
        <button (click)="displayCertificationsPage()">Certificaciones</button> &nbsp;

    </div> <br> <br> <br>



    <div *ngIf="!certificationPage" class="container">
        <h3>OCEANS ACADEMY</h3>
        <br>
        <form [formGroup]="pageForm" id="pageForm" (ngSubmit)="onSubmit()">
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título del banner</mat-label>
                <input name="bannerTitle" formControlName="bannerTitle" (ngModel)="academyEnglishData.bannerTitle" #title1 matInput maxlength="100">
                <mat-hint align="end">{{title1.value.length}} / 100</mat-hint>
                <mat-error>El monto es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción del banner</mat-label>
                <textarea class="height-inputs" matInput name="bannerIntro" (ngModel)="academyEnglishData.bannerIntro" formControlName="bannerIntro"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal</mat-label>
                <textarea class="height-inputs" matInput name="firstSectionIntro" (ngmodel)="academyEnglishData.firstSectionIntro" formControlName="firstSectionIntro"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título segunda sección</mat-label>
                <input name="secondSectionTitle" formControlName="secondSectionTitle" (ngmodel)="academyEnglishData.secondSectionTitle" #title2 matInput maxlength="200">
                <mat-hint align="end">{{title2.value.length}} / 200</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción segunda sección</mat-label>
                <textarea class="height-inputs" matInput name="secondSectionDescription" (ngmodel)="academyEnglishData.secondSectionDescription" formControlName="secondSectionDescription"></textarea>
                <mat-error>La descripción es requerida</mat-error>
            </mat-form-field> <br>

            <!-- <div class="upload-file-container">
                <span><strong>Imagen segunda sección</strong></span> <br>
                <input class="upload-file" type="file" name="secondSectionImage" formControlName="secondSectionImage">
            </div> -->

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título tercer sección</mat-label>
                <input name="thirdSectionCertificationsTitle" (ngmodel)="academyEnglishData.thirdSectionCertificationsTitle" formControlName="thirdSectionCertificationsTitle" #title3 matInput maxlength="200">
                <mat-hint align="end">{{title3.value.length}} / 200</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción de testimonio</mat-label>
                <textarea class="height-inputs" matInput name="fourthSectionTestimonyDescription" (ngmodel)="academyEnglishData.fourthSectionTestimonyDescription" formControlName="fourthSectionTestimonyDescription"></textarea>
                <mat-error>La descripción es requerida</mat-error>
            </mat-form-field> <br>

            <!-- <div class="upload-file-container">
                <span><strong>Imagen Testimonio</strong></span> <br>
                <input class="upload-file" type="file" name="thirdSectionImage" formControlName="thirdSectionImage">
            </div> -->

        </form>

        <div class="submit-button-container">
            <button form="pageForm" mat-button>Guardar cambios</button>
        </div>

    </div>

    <div *ngIf="certificationPage" class="container">
        <h3>CERTIFICACIONES</h3>
        <br>
        <div class="container subinternal-menu">
            <button (click)="displayViewCertifications()">Ver Certificaciones</button> &nbsp;
            <button (click)="displayCreateCertification('0')">Crear Certificación</button> &nbsp;
            <button (click)="displayTermsAndConditions()">Terminos y condiciones</button>
            <br> <br>
        </div>
        <!--VIEW EXISTING CERTIFICATIONS-->
        <div *ngIf="certificationsSection == 'certifications table'">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataCertifications" class="mat-elevation-z8">

                    <ng-container matColumnDef="titleEng">
                        <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Certificación Inglés </th>
                        <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                    </ng-container>

                    <ng-container matColumnDef="titleEsp">
                        <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Certificación Español </th>
                        <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                    </ng-container>

                    <ng-container matColumnDef="icons" stickyEnd>
                        <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                        <td class="tableCells" mat-cell *matCellDef="let element">
                            <div class="cell-icons">
                                <a (click)="deleteCertification(element.idCertificationEng)" matTooltip="Eliminar">
                                    <mat-icon>delete</mat-icon>
                                </a>
                                <a (click)="editCertification(element)" matTooltip="Editar">
                                    <mat-icon>edit</mat-icon>
                                </a>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

        </div>

        <!--CREATE CERTIFICATIONS-->
        <div *ngIf="certificationsSection == 'creating certification'">
            <h4>Crear Nueva Certificación</h4>
            <br>

            <span class="text-success subtitle">Certificación en Inglés</span>

            <form [formGroup]="certificationForm" id="certificationForm" (ngSubmit)="submitCertification()">
                <!-- <div class="upload-file-container">
                    <span><strong>Imagen Certificación</strong></span> <br>
                    <input class="upload-file" type="file" name="image" formControlName="image" (change)="getFiles($event.target.files)">
                </div> -->

                <!--ENGLISH-->
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título Certificación</mat-label>
                    <input name="titleEng" formControlName="titleEng" #title1Eng matInput maxlength="200" (ngModel)="dataCertifications.titleEng">
                    <mat-hint align="end">{{title1Eng.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen Banner Desktop</mat-label>
                    <input name="imgUrlBanner" formControlName="imgUrlBanner" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen Banner Mobile</mat-label>
                    <input name="imgUrlBannerMobile" formControlName="imgUrlBannerMobile" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen Thumbnail</mat-label>
                    <input name="imgUrlThumbnail" formControlName="imgUrlThumbnail" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título del Banner</mat-label>
                    <input name="bannerTitleEng" formControlName="bannerTitleEng" #bannerTitleEng matInput maxlength="200" (ngModel)="dataCertifications.bannerTitleEng">
                    <mat-hint align="end">{{bannerTitleEng.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Introducción de la certificación</mat-label>
                    <textarea class="height-inputs" matInput name="firstSectionIntroEng" formControlName="firstSectionIntroEng" (ngModel)="dataCertifications.firstSectionIntroEng"></textarea>
                    <mat-error>La introducción es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título 1er cuadro</mat-label>
                    <input name="secondSectionExamFormatTitleEng" formControlName="secondSectionExamFormatTitleEng" (ngModel)="dataCertifications.secondSectionExamFormatTitleEng" #secondSectionTitleEng matInput maxlength="100">
                    <mat-hint align="end">{{secondSectionTitleEng.value.length}} /100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Detalles 1er cuadro</mat-label>
                    <textarea class="height-inputs" matInput name="secondSectionExamFormatDetailsEng" (ngModel)="dataCertifications.secondSectionExamFormatDetailsEng" formControlName="secondSectionExamFormatDetailsEng"></textarea>
                    <mat-error>Los detalles son requeridos</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título 2do cuadro</mat-label>
                    <input name="secondSectionDurationTitleEng" formControlName="secondSectionDurationTitleEng" (ngModel)="dataCertifications.secondSectionDurationTitleEng" #thirdSectionTitleEng matInput maxlength="100">
                    <mat-hint align="end">{{thirdSectionTitleEng.value.length}} /100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Detalles 2do cuadro</mat-label>
                    <textarea class="height-inputs" matInput name="secondSectionDurationDetailsEng" (ngModel)="dataCertifications.secondSectionDurationDetailsEng" formControlName="secondSectionDurationDetailsEng"></textarea>
                    <mat-error>Los detalles son requeridos</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título 3er cuadro</mat-label>
                    <input name="secondSectionPriceTitleEng" formControlName="secondSectionPriceTitleEng" (ngModel)="dataCertifications.secondSectionPriceTitleEng" #title4Eng matInput maxlength="100">
                    <mat-hint align="end">{{title4Eng.value.length}} /100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Detalles 3er cuadro</mat-label>
                    <textarea class="height-inputs" matInput name="secondSectionPriceDetailsEng" (ngModel)="dataCertifications.secondSectionPriceDetailsEng" formControlName="secondSectionPriceDetailsEng"></textarea>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Recomendado para</mat-label>
                    <textarea class="height-inputs" matInput name="recomendedForEng" (ngModel)="dataCertifications.recomendedForEng" formControlName="recomendedForEng"></textarea>
                    <mat-error>El texto es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Prerequisitos</mat-label>
                    <textarea class="height-inputs" matInput name="prerequisitesEng" (ngModel)="dataCertifications.prerequisitesEng" formControlName="prerequisitesEng"></textarea>
                    <mat-error>El texto es requerido</mat-error>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle">Certificación en Español</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título Certificación</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #title1 matInput maxlength="200" (ngModel)="dataCertifications.titleEsp">
                    <mat-hint align="end">{{title1.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título del Banner</mat-label>
                    <input name="bannerTitleEsp" formControlName="bannerTitleEsp" #bannerTitleEsp matInput maxlength="200" (ngModel)="dataCertifications.bannerTitleEsp">
                    <mat-hint align="end">{{bannerTitleEsp.value.length}} / 200</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Introducción de la certificación</mat-label>
                    <textarea class="height-inputs" matInput name="firstSectionIntroEsp" formControlName="firstSectionIntroEsp" (ngModel)="dataCertifications.firstSectionIntroEsp"></textarea>
                    <mat-error>La introducción es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título 1er cuadro</mat-label>
                    <input name="secondSectionExamFormatTitleEsp" formControlName="secondSectionExamFormatTitleEsp" (ngModel)="dataCertifications.secondSectionExamFormatTitleEsp" #secondSectionTitleEsp matInput maxlength="100">
                    <mat-hint align="end">{{secondSectionTitleEsp.value.length}} /100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Detalles 1er cuadro</mat-label>
                    <textarea class="height-inputs" matInput name="secondSectionExamFormatDetailsEsp" (ngModel)="dataCertifications.secondSectionExamFormatDetailsEsp" formControlName="secondSectionExamFormatDetailsEsp"></textarea>
                    <mat-error>Los detalles son requeridos</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título 2do cuadro</mat-label>
                    <input name="secondSectionDurationTitle" formControlName="secondSectionDurationTitleEsp" (ngModel)="dataCertifications.secondSectionDurationTitleEsp" #thirdSectionTitleEsp matInput maxlength="100">
                    <mat-hint align="end">{{thirdSectionTitleEsp.value.length}} /100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Detalles 2do cuadro</mat-label>
                    <textarea class="height-inputs" matInput name="secondSectionDurationDetailsEsp" (ngModel)="dataCertifications.secondSectionDurationDetailsEsp" formControlName="secondSectionDurationDetailsEsp"></textarea>
                    <mat-error>Los detalles son requeridos</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título 3er cuadro</mat-label>
                    <input name="secondSectionPriceTitleEsp" formControlName="secondSectionPriceTitleEsp" (ngModel)="dataCertifications.secondSectionPriceTitleEsp" #title4Esp matInput maxlength="100">
                    <mat-hint align="end">{{title4Esp.value.length}} /100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Detalles 3er cuadro</mat-label>
                    <textarea class="height-inputs" matInput name="secondSectionPriceDetailsEsp" (ngModel)="dataCertifications.secondSectionPriceDetailsEsp" formControlName="secondSectionPriceDetailsEsp"></textarea>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Recomendado para</mat-label>
                    <textarea class="height-inputs" matInput name="recomendedForEsp" (ngModel)="dataCertifications.recomendedForEsp" formControlName="recomendedForEsp"></textarea>
                    <mat-error>El texto es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Prerequisitos</mat-label>
                    <textarea class="height-inputs" matInput name="prerequisitesEsp" (ngModel)="dataCertifications.prerequisitesEsp" formControlName="prerequisitesEsp"></textarea>
                    <mat-error>El texto es requerido</mat-error>
                </mat-form-field> <br>

                <!--OTHER DETAILS-->
                <span class="text-success subtitle">Otros detalles</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>URL Paypal precio normal</mat-label>
                    <input name="payPalUrlNormal" formControlName="payPalUrlNormal" #payPalUrlNormal1 matInput maxlength="200" (ngModel)="dataCertifications.payPalUrlNormal">
                    <mat-hint align="end">{{payPalUrlNormal1.value.length}} / 200</mat-hint>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>URL Paypal precio promoción</mat-label>
                    <input name="payPalUrlPromo" formControlName="payPalUrlPromo" #payPalUrlPromo1 matInput maxlength="200" (ngModel)="dataCertifications.payPalUrlPromo">
                    <mat-hint align="end">{{payPalUrlPromo1.value.length}} / 200</mat-hint>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width medium-inputs">
                    <mat-label>Precio normal</mat-label>
                    <input name="priceNormal" placeholder="0.00" type="number" maxlength="18" #priceNormal1 matInput formControlName="priceNormal">
                    <mat-hint align="end">{{priceNormal1.value.length}} / 18</mat-hint>
                    <mat-error>El el monto es requerido</mat-error>
                </mat-form-field>&nbsp;

                <mat-form-field class="example-full-width medium-inputs">
                    <mat-label>Precio promoción</mat-label>
                    <input name="pricePromo" placeholder="0.00" type="number" maxlength="18" #pricePromo1 matInput formControlName="pricePromo">
                    <mat-hint align="end">{{pricePromo1.value.length}} / 18</mat-hint>
                    <mat-error>El el monto es requerido</mat-error>
                </mat-form-field>&nbsp;

            </form>
            <div class="submit-button-container">
                <button form="certificationForm" mat-button>Guardar cambios</button>
            </div>
        </div>

        <!--CREATE CERTIFICATIONS-->
        <div *ngIf="certificationsSection == 'terms and conditions'">
            <form [formGroup]="termsConditionsForm" id="termsConditionsForm" (ngSubmit)="submitTermsConditions()">

                <span class="text-success subtitle">Inglés</span>
                <br>
                <!--ENGLISH-->
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #titleTermsEng matInput maxlength="100" (ngModel)="dataTermsConditions.titleEng">
                    <mat-hint align="end">{{titleTermsEng.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="big-textarea" matInput name="descriptionEng" formControlName="descriptionEng" (ngModel)="dataTermsConditions.DescriptionEng"></textarea>
                    <mat-error>La descripción es requerida</mat-error>
                </mat-form-field> <br>

                <span class="text-success subtitle">Español</span>
                <br>
                <br>
                <br>
                <!--SPANISH-->
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #titleTermsEsp matInput maxlength="100" (ngModel)="dataTermsConditions.titleEsp">
                    <mat-hint align="end">{{titleTermsEsp.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="big-textarea" matInput name="descriptionEsp" formControlName="descriptionEsp" (ngModel)="dataTermsConditions.DescriptionEsp"></textarea>
                    <mat-error>La descripción es requerida</mat-error>
                </mat-form-field> <br>

            </form>
            <div class="submit-button-container">
                <button form="termsConditionsForm" mat-button>Guardar cambios</button>
            </div>
        </div>


    </div>

</div>