import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class ContactPageService {

  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
  }
  // Principal page
  getContactPricipalData() {
    return this.http.get(this.url + 'ContactPagesPrincipal/GetContactPrincipalData', this.httpOptions);
  }
  createUpdateContactPagePrincipal(data) {
    return this.http.post(this.url + 'ContactPagesPrincipal/CreateUpdateContactPagePrincipal', data, this.httpOptions);
  }

  // Addresses
  getAddressesData() {
    return this.http.get(this.url + 'ContactPageAddresses/GetAddressData', this.httpOptions);
  }

  createUpdateAddress(data) {
    return this.http.post(this.url + 'ContactPageAddresses/CreateUpdateAddress', data, this.httpOptions);
  }
  deleteAddress(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'ContactPageAddresses/DeleteAddress', id, httpOptions);
  }

  // Phones
  getPhonesData() {
    return this.http.get(this.url + 'ContactPagePhones/GetPhonesData', this.httpOptions);
  }

  createUpdatePhone(data) {
    console.log(data);
    return this.http.post(this.url + 'ContactPagePhones/CreateUpdatePhone', data, this.httpOptions);
  }
  deletePhone(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'ContactPagePhones/DeletePhone', id, httpOptions);
  }
  // Emails
  getEmailsData() {
    return this.http.get(this.url + 'ContactPageEmails/GetEmailData', this.httpOptions);
  }

  createUpdateEmail(data) {
    console.log(data);
    return this.http.post(this.url + 'ContactPageEmails/CreateUpdateEmail', data, this.httpOptions);
  }
  deleteEmail(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'ContactPageEmails/DeleteEmail', id, httpOptions);
  }
}
