import { Component, ViewChild, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith, filter } from 'rxjs/operators';

import { BlogTagService } from '../../../services/blog-tag.service'

interface Tag { id: number; name: string }

@Component({
  selector: 'app-our-blog-tag-autocomplete',
  templateUrl: './our-blog-tag-autocomplete.component.html',
  styleUrls: ['./our-blog-tag-autocomplete.css']
})
export class OurBlogTagAutocompleteComponent {
  selectedTags = [];
  allTags = [];
  filteredTags: Observable<Tag[]>;
  tagsCtrl = new FormControl('');
  readonly separatorKeysCodes = [ENTER, COMMA];
  @ViewChild('tagsInput', { static: false }) tagsInput: ElementRef<HTMLInputElement>;
  @Input() defaultValues: Tag[] = [];
  @Output() onTagChange = new EventEmitter<Tag[]>();
  
  constructor(
    private readonly blogTagService: BlogTagService
  ) {}

  ngOnInit() {
    this.fetchTags();
  }

  remove({ id }: Tag) {
    this.selectedTags = this.selectedTags.filter((st) => st.id !== id);
    this.tagsCtrl.setValue('');
    this.onTagChange.emit(this.selectedTags);
  }

  select({ option }: MatAutocompleteSelectedEvent) {
    this.selectedTags.push(option.value);
    this.tagsCtrl.setValue('');
    this.tagsInput.nativeElement.value = '';
    this.onTagChange.emit(this.selectedTags);
  }

  fetchTags() {
    this.blogTagService.getBlogTags().subscribe(d => {
      this.allTags = d;
      this.selectedTags = this.defaultValues || [];
      this.setFilterTags();
    });
  }

  setFilterTags() {
    this.filteredTags = this.tagsCtrl.valueChanges.pipe(
      startWith(''),
      filter(keyword => typeof keyword === 'string'),
      map(keyword => this.filterTags(keyword))
    );
  }

  filterTags(keyword: string) {
    return  this.allTags
      .filter(({ id, name }: Tag) => {
        const a = name.toLowerCase();
        const b = keyword.toLowerCase();

        return a.includes(b) && !this.selectedTags.some(st => id === st.id);
      });
  }
}
