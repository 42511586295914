import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { WebinarsPageService } from '../../services/webinars-page.service';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';

@Component({
  selector: 'app-webinars',
  templateUrl: './webinars.component.html',
  styleUrls: ['./webinars.component.css']
})
export class WebinarsComponent implements OnInit {

  displayedColumns: string[] = ['titleEng', 'titleEsp', 'icons'];
  progressBar: boolean;
  emulatingSection: string = 'Webinars';
  actionTitle: string = 'Webinars Existentes';
  webinarForm: FormGroup;

  emulatingSubsection: string = 'View webinars Table';

  dataWebinars: any;
  dataToSend: any;

  constructor(
    private webinarsPageService: WebinarsPageService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
this.getWebinarsData();
  }

  displayWebinarsPage() {
    this.emulatingSection = 'Webinars';
    this.getWebinarsData();
  }
  getWebinarsData() {
    this.webinarsPageService.getWebinarsData().subscribe((res: any) => {
      this.dataWebinars = res;
    });
  }

  displayCreateNewWebinar(id, data, action) {
    this.actionTitle = action;
    this.emulatingSubsection = 'Creating Webinar';
    this.webinarForm = this.formBuilder.group({
      idWebinar: [id],
      titleEng: [data.titleEng, Validators.required],
      titleEsp: [data.titleEsp, Validators.required],
      dateEng: [data.dateEng, Validators.required],
      dateEsp: [data.dateEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      meetingUrl: [data.meetingUrl, Validators.required]
    });
  }

  submitWebinar() {
    if (this.webinarForm.valid) {
      this.dataToSend = this.webinarForm.value;
      this.createUpdateWebinar();
    }
  }

  createUpdateWebinar() {
    this.progressBar = true;
    this.webinarsPageService.createUpdateWebinar(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'View webinars Table';
      this.actionTitle = 'Webinars Existentes';
      this.progressBar = false;
      this.getWebinarsData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }

  delete(id) {
    console.log(id);
    this.openConfirmationMessageDelete(id);
  }
  openConfirmationMessageDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Elemento', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete

        this.webinarsPageService.deleteWebinar(id).subscribe((response) => {
          this.openSnackBar('¡Eliminado correctamente!');
          this.getWebinarsData();
          this.progressBar = false;
        }, err => {
          this.openSnackBar('¡Error de servidor!');
          console.log(err);
        });
      }
    });
  }

}

