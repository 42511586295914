<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="displayPrincipalPage()">Página principal</button> &nbsp;
        <button (click)="displayContactFooter()">Footer de Contact</button> &nbsp;
        <!--  <button (click)="displayTeamsPage()">Teams</button> &nbsp; -->

    </div> <br> <br> <br>
    <!--PRINCIPAL PAGE-->
    <div *ngIf="emulatingSection == 'Principal Page'">
        <h4><strong>{{actionTitle}}</strong></h4>
        <br>
        <form [formGroup]="whatAreULookingForForm" id="whatAreULookingForForm" (ngSubmit)="submitPrincipalPageForm()">

            <!-- ENGLISH-->
            <span class="text-success subtitle">INFORMACIÓN EN INGLÉS</span>
            <br>
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título principal 1er sección </mat-label>
                <input matInput name="FirstSectionTitleEng" formControlName="FirstSectionTitleEng" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título 1er item </mat-label>
                <input matInput name="FirstItemTitleEng" formControlName="FirstItemTitleEng" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción 1er item </mat-label>
                <textarea class="height-inputs-medium" matInput name="FirtItemDescriptionEng" formControlName="FirtItemDescriptionEng"></textarea>
                <mat-error>La descripción requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título 2do item </mat-label>
                <input matInput name="SecondItemTitleEng" formControlName="SecondItemTitleEng" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción item </mat-label>
                <textarea class="height-inputs-medium" matInput name="SecondItemDescriptionEng" formControlName="SecondItemDescriptionEng"></textarea>
                <mat-error>La descripción requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título 3er item </mat-label>
                <input matInput name="ThirdItemTitleEng" formControlName="ThirdItemTitleEng" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción 2do item </mat-label>
                <textarea class="height-inputs-medium" matInput name="ThirdItemDescriptionEng" formControlName="ThirdItemDescriptionEng"></textarea>
                <mat-error>La descripción requerida</mat-error>
            </mat-form-field> <br>

            <!-- SPANISH-->
            <span class="text-success subtitle">INFORMACIÓN EN ESPAÑOL</span>
            <br>
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título 1er sección </mat-label>
                <input matInput name="FirstSectionTitleEsp" formControlName="FirstSectionTitleEsp" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título 1er item </mat-label>
                <input matInput name="FirstItemTitleEsp" formControlName="FirstItemTitleEsp" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción 1er item </mat-label>
                <textarea class="height-inputs-medium" matInput name="FirtItemDescriptionEsp" formControlName="FirtItemDescriptionEsp"></textarea>
                <mat-error>La descripción requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título 2do item </mat-label>
                <input matInput name="SecondItemTitleEsp" formControlName="SecondItemTitleEsp" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción item </mat-label>
                <textarea class="height-inputs-medium" matInput name="SecondItemDescriptionEsp" formControlName="SecondItemDescriptionEsp"></textarea>
                <mat-error>La descripción requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título 3er item </mat-label>
                <input matInput name="ThirdItemTitleEsp" formControlName="ThirdItemTitleEsp" />
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Descripción 2do item </mat-label>
                <textarea class="height-inputs-medium" matInput name="ThirdItemDescriptionEsp" formControlName="ThirdItemDescriptionEsp"></textarea>
                <mat-error>La descripción requerida</mat-error>
            </mat-form-field> <br>

        </form>
        <div class="submit-button-container">
            <button form="whatAreULookingForForm" mat-button>Guardar cambios</button>
        </div>

    </div>

    <!--CREATE ADDRESS SECTION-->

    <div *ngIf="emulatingSection == 'Contact Footer'">

        <h4><strong>{{actionTitle}}</strong></h4>

        <div *ngIf="emulatingSubsection == 'Addresses Table'">
            <h5 style="text-align: left;">Ubicaciones</h5>
            <div class="container subinternal-menu">
                <button (click)="displayCreateAddress('0', '', 'Agregar nueva ubicación')">Agregar nueva Ubicación</button> &nbsp;

                <br> <br>
            </div>

            <table mat-table [dataSource]="dataAddress" class="mat-elevation-z8">

                <ng-container matColumnDef="column1">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Ubicación </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.address}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'Address')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayCreateAddress(element.id, element, 'Editar Ubicación')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
            <br><br>

            <!--Phone Numbers-->
            <h5 style="text-align: left;">Números telefónicos</h5>
            <div class="container subinternal-menu">
                <button (click)="displayCreatePhone('0', '', 'Agregar nuevo teléfono')">Agregar nuevo teléfono</button> &nbsp;

                <br> <br><br>
            </div>
            <table mat-table [dataSource]="dataPhones" class="mat-elevation-z8">

                <ng-container matColumnDef="column1">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Número telefónico </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'Phone')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayCreatePhone(element.id, element, 'Editar Teléfono')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
            <br><br><br>

            <!-- EMAILS-->
            <h5 style="text-align: left;">Corres</h5>
            <div class="container subinternal-menu">
                <button (click)="displayCreateEmail('0', '', 'Agregar nuevo correo')">Agregar nuevo correo</button> &nbsp;

                <br> <br><br>
            </div>
            <table mat-table [dataSource]="dataEmails" class="mat-elevation-z8">

                <ng-container matColumnDef="column1">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Correo </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="delete(element.id, 'Email')" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayCreateEmail(element.id, element, 'Editar Correo')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>
        <!-- Create New Address -->
        <div *ngIf="emulatingSubsection == 'Create Update Address'">
            <form [formGroup]="addressesForm" id="addressesForm" (ngSubmit)="submitAddress()">

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Ubicación</mat-label>
                    <input name="Address" formControlName="Address" #address matInput maxlength="500">
                    <mat-hint align="end">{{address.value.length}} / 500</mat-hint>
                    <mat-error>El número es requerido</mat-error>
                </mat-form-field> <br>

            </form>
            <div class="submit-button-container">
                <button (click)="emulatingSubsection = 'Addresses Table'; actionTitle = 'Información existente Contact Footer'" mat-button>Cancelar</button> &nbsp;
                <button form="addressesForm" mat-button>Guardar cambios</button>

            </div>
        </div>
        <!-- Create New Phone -->
        <div *ngIf="emulatingSubsection == 'Create Update Phone'">
            <form [formGroup]="phonesForm" id="phonesForm" (ngSubmit)="submitPhone()">

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Número telefónico</mat-label>
                    <input name="PhoneNumber" formControlName="PhoneNumber" #phone matInput maxlength="30">
                    <mat-hint align="end">{{phone.value.length}} / 30</mat-hint>
                    <mat-error>El número es requerido</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button (click)="emulatingSubsection = 'Addresses Table'; actionTitle = 'Información existente Contact Footer'" mat-button>Cancelar</button> &nbsp;
                <button form="phonesForm" mat-button>Guardar cambios</button>
            </div>
        </div>
        <!-- Create New Email -->
        <div *ngIf="emulatingSubsection == 'Create Update Email'">
            <form [formGroup]="emailsForm" id="emailsForm" (ngSubmit)="submitEmail()">

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Correo</mat-label>
                    <input name="email" formControlName="email" #email matInput maxlength="200">
                    <mat-hint align="end">{{email.value.length}} / 200</mat-hint>
                    <mat-error>El correo es requerido</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button (click)="emulatingSubsection = 'Addresses Table'; actionTitle = 'Información existente Contact Footer'" mat-button>Cancelar</button> &nbsp;
                <button form="emailsForm" mat-button>Guardar cambios</button>
            </div>
        </div>

    </div>
</div>