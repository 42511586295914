import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'oceans-page-administrator';
  public identity;
  public token;

  constructor(private userService: UserService) {
    this.identity = this.userService.getIdentity();
  }

  ngOnInit() {
    if (localStorage.getItem('emulating') === null) {
      localStorage.setItem('emulating', 'ENG');
    }
  }

  ngDoCheck() {
    this.token = this.userService.getTockenLocalStorage();
    this.identity = this.userService.getIdentity();

  }
}
