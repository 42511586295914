<div class="example-container" [class.example-is-mobile]="mobileQuery.matches" *ngIf="shouldRun">
    <mat-toolbar class="example-toolbar sideNav-background">
        <button id="menu-button" mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
        <h1 id="sidenavTitle" class="example-app-name sidenav-title">ADMINISTRADOR PAGINA WEB</h1>


        <div id="Logout-icon">
            <mat-form-field class="language-select-container">
                <mat-label>Lenguage</mat-label>
                <mat-select disableRipple [(value)]="emulatingOn">
                    <mat-option (click)="selectEnglish()" value="ENGLISH">Inglés</mat-option>
                    <mat-option (click)="selectSpanish()" value="SPANISH">Espanol</mat-option>
                </mat-select>
            </mat-form-field>
            <a id="logOutLink" (click)="logout()"> Cerrar Sesión</a>
            <a id="logOUtMatIcon">
                <mat-icon (click)="logout()">clear</mat-icon>
            </a>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav #snav [opened]="screenWidth > 840" [mode]="(screenWidth > 840) ? 'side' : 'over'" [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <mat-nav-list class="navbar-links" (click)="closeSideNav()">
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'inicio'">
                   Home
                </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'services'">
                    Our Services
                  </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'careers'">
                    Careers
                  </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'our-blog'">
                    Our Blog
                  </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'about-us'">
                    About Us
                  </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'webinars'">
                    Webinars
                  </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'contact'">
                    Contact
                  </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'oceans-academy'">
                  Oceans Academy
                </a>
                <a mat-list-item [routerLinkActive]="['activeSubTab']" [routerLink]="'footer'">
                    Footer Section
                  </a>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <div>
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>