import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {
  public url: string;
  public urlPages: string;
  private httpOptions;
  public token: string;
  private headersObject;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');

    this.headersObject = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.token,
    });

    this.httpOptions = {
      headers: this.headersObject,
    };
  }

  getHomePricipalData() {
    return this.http.get(this.url + 'HomePagesPrincipal/GetHomePrincipalData', this.httpOptions);
  }
  createUpdateHomePagePrincipal(data) {
    return this.http.post(this.url + 'HomePagesPrincipal/CreateUpdateHomePagePrincipal', data, this.httpOptions);
  }
  // WHY COSTA RICA
  getWhyCrData() {
    return this.http.get(this.url + 'HomePageWhyCrs/GetWhyCrData', this.httpOptions);
  }
  createUpdateWhyCr(data) {
    return this.http.post(this.url + 'HomePageWhyCrs/CreateUpdateWhyCr', data, this.httpOptions);
  }
  deleteWhyCrItem(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'HomePageWhyCrs/DeleteWhyCrItem', id, httpOptions);
  }
  // OUR TEAM

  getOurTeamData() {
    return this.http.get(this.url + 'HomePageOurTeams/GetOurTeamData', this.httpOptions);
  }
  createUpdateOurTeam(data) {
    return this.http.post(this.url + 'HomePageOurTeams/CreateUpdateOurTeam', data, this.httpOptions);
  }
  deleteOurTeamItem(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'HomePageOurTeams/DeleteOurTeamItem', id, httpOptions);
  }

  // WHAT CLIENTS SAY

  getWhatClientsSayData() {
    return this.http.get(this.url + 'WhatClientsSays/GetWhatClientsSayData', this.httpOptions);
  }
  createUpdateWhatClientsSay(data) {
    return this.http.post(this.url + 'WhatClientsSays/CreateUpdateWhatClientsSay', data, this.httpOptions);
  }
  deleteWhatClientsSayItem(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'WhatClientsSays/DeleteWhatClientsSayItem', id, httpOptions);
  }

  // SUCCESS STORIES

  getSuccessStoriesData() {
    return this.http.get(this.url + 'SuccessStories/GetSuccessStoriesData', this.httpOptions);
  }
  createUpdateSuccessStories(data) {
    return this.http.post(this.url + 'SuccessStories/CreateUpdateSuccessStories', data, this.httpOptions);
  }
  deleteSuccessStoryItem(id) {
    const httpOptions = {
      params: new HttpParams().set('id', id),
      headers: this.headersObject,
    };
    return this.http.put(this.url + 'SuccessStories/DeleteSuccessStoryItem', id, httpOptions);
  }

}
