<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="emulatingSection = 'Intro'">Intro principal</button> &nbsp;
        <button (click)="displayBlogs()">Blogs</button> &nbsp;
        <button (click)="displayAuthors()">Authors</button> &nbsp;
        <button (click)="displayTags()">Tags</button> &nbsp;
        <!--  <button (click)="displaySuccessStoriesTable()">Success Stories</button> &nbsp; -->
    </div> <br> <br> <br>
    <!--PRINCIPAL PAGE-->
    <div *ngIf="emulatingSection == 'Intro'">
        <h4>Introducción principal de Our Blog page</h4>
        <br>
        <form [formGroup]="introForm" id="introForm" (ngSubmit)="submitPrincipalPageForm()">

            <!-- ENGLISH-->
            <span class="text-success subtitle">Información en Inglés</span>
            <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título Principal</mat-label>
                <input name="titleEng" formControlName="titleEng" #titleEng matInput maxlength="400">
                <mat-hint align="end">{{titleEng.value.length}} / 400</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="IntroEng" formControlName="IntroEng"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>


            <!-- SPANISH-->
            <span class="text-success subtitle">Información en Español</span>
            <br>
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título Principal</mat-label>
                <input name="titleEsp" formControlName="titleEsp" #titleEsp matInput maxlength="400">
                <mat-hint align="end">{{titleEsp.value.length}} / 400</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción principal </mat-label>
                <textarea class="height-inputs" matInput name="IntroEsp" formControlName="IntroEsp"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

        </form>
        <div class="submit-button-container">
            <button form="introForm" mat-button>Guardar cambios</button>
        </div>
    </div>

    <!--BLOGS-->
    <div *ngIf="emulatingSection == 'blogs'">
        <h4>{{actionTitle}}</h4>
        <br>

        <div *ngIf="!loadBlogs && !createUpdateBlog">
            <div><br> Espera, se están cargando los datos...
            </div>
            <div class="spinner-border m-2" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div *ngIf="blogSubmit">
            <div><br> Espera, se están guardando los datos...
            </div>
            <div class="spinner-border m-2" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- BLOGS TABLE -->
        <div *ngIf="loadBlogs && !createUpdateBlog">
            <div class="container subinternal-menu mb-4">
                <button (click)="displayCreateNewBlog(0, '0', 'Crear Nuevo Blog')">Crear Blog</button> &nbsp;
            </div>
            <div class="container mb-4">
                <div class="row">
                    <div class="col-md-6">
                        <mat-form-field class="filters-container">
                            <mat-label>Filtrar Datos</mat-label>
                            <input matInput (keyup)="applyFilter(filterKeyword, searchTags)" [(ngModel)]="filterKeyword" placeholder="Empieza una busqueda">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <app-our-blog-tag-autocomplete (onTagChange)="applyFilter(filterKeyword, $event)"></app-our-blog-tag-autocomplete>
                    </div>
                </div>
            </div>
            <div class="table-responsive">
                <table mat-table [dataSource]="dataBlogs" class="mat-elevation-z8 global-table" matSort>

                    <ng-container matColumnDef="imgBannerMobile">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef> Imagen Mobile </th>
                        <td class="tableCells" mat-cell *matCellDef="let element"> <img width="100px" src="{{element.imgBannerMobile}}" alt=""> </td>
                    </ng-container>

                    <ng-container matColumnDef="bannerTitle">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Título </th>
                        <td class="tableCells" mat-cell *matCellDef="let element"> {{element.bannerTitle}} </td>
                    </ng-container>

                    <ng-container matColumnDef="tags">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Tags </th>
                        <td class="tableCells" mat-cell *matCellDef="let element"> {{mapTags(element.tags)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="creationDate">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef> Fecha creación </th>
                        <td class="tableCells" mat-cell *matCellDef="let element"> {{element.creationDate | date}} </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef> Estado </th>
                        <td class="tableCells" mat-cell *matCellDef="let element">
                            <mat-slide-toggle [(ngModel)]="element.published" (change)="publishUnpublisBlog(element.idBlog, element.published)"><span class="green-label" *ngIf="element.published">Publicado</span><span class="red-label" *ngIf="!element.published">No
                  Publicado</span></mat-slide-toggle>
                        </td>

                    </ng-container>

                    <ng-container matColumnDef="icons">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef> Acciones </th>
                        <td class="tableCells" mat-cell *matCellDef="let element">
                            <a class="button-icons" (click)="displayCreateNewBlog(element.idBlog, element, 'Editar Blog')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a class="button-icons" (click)="delete(element.idBlog)" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <!-- <a class="button-icons" (click)="displayCreateNewOpenPosition(0, element, 'Clonar posición')" matTooltip="Clonar">
                        <mat-icon>file_copy</mat-icon>
                    </a> -->
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns; "></tr>

                </table>
            </div>
            <mat-paginator id="paginator-jr" [pageSizeOptions]="[50, 150, 250, 400]"></mat-paginator>
        </div>

        <!-- CREATING/UPDATING BLOG -->
        <div *ngIf="createUpdateBlog && !blogSubmit">
            <form style="padding: 5px 20px 5px 20px;" [formGroup]="blogsForm" id="blogsForm" (ngSubmit)="submitBlog(imgDesktopInput, imgMobileInput)">
                <br>

                <div style="display: flex;">
                    <div>
                        <label><strong>Imagen de banner en Desktop</strong></label> <br>
                        <img style="width: 280px;" src="{{imgSelectedDesktop}}" alt="">
                        <div class="input-file-container">
                            <label class="label-file" for="imgFileDesktop"><i class="fa fa-upload upload-icon" aria-hidden="true"></i>
                Choose
                File</label>
                        </div>
                        <input hidden id="imgFileDesktop" #imgDesktopInput class="input-file" type="file" accept="image/x-png,image/gif,image/jpeg" formControlName="imgBannerDesktop" (change)="onSelectFile($event,'desktop')">
                        <div *ngIf="submitButtonDirty">
                            <mat-error *ngIf="blogsForm.get('imgBannerDesktop').hasError('required')">
                                La imagen es requerida
                            </mat-error>
                        </div>
                    </div> &nbsp;
                    <div>
                        <label><strong>Imagen de banner en Mobile</strong></label> <br>
                        <img style="width: 280px;" src="{{imgSelectedMobile}}" alt="">
                        <div class="input-file-container">
                            <label class="label-file" for="imgFileMobile"><i class="fa fa-upload upload-icon" aria-hidden="true"></i>
                Choose
                File</label>
                        </div>
                        <input hidden id="imgFileMobile" #imgMobileInput class="input-file" type="file" accept="image/x-png,image/gif,image/jpeg" formControlName="imgBannerMobile" (change)="onSelectFile($event,'mobile')">
                        <div *ngIf="submitButtonDirty">
                            <mat-error *ngIf="blogsForm.get('imgBannerMobile').hasError('required')">
                                La imagen es requerida
                            </mat-error>
                        </div>
                    </div>
                </div>

                <div class="row" style="padding-left: 15px;">
                    <mat-form-field>
                        <mat-label>Fecha de publicación</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="creationDate">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="blogsForm.get('creationDate').hasError('required')">La fecha es requerida
                        </mat-error>
                    </mat-form-field>
                </div>
                <div style="width: 95%;" class="row">

                <div class="col-sm">
                    <mat-form-field class="example-full-width large-inputs">
                        <mat-label>Título del blog</mat-label>
                        <input name="title" formControlName="bannerTitle" #titleBanner matInput maxlength="150">
                        <mat-hint align="end">{{titleBanner.value.length}} / 150</mat-hint>
                        <mat-error>El título es requerido</mat-error>
                    </mat-form-field>
                    <br>
                </div>

                  <div class="col-sm">
                    <mat-form-field class="example-full-width large-inputs">
                        <mat-label>Slug para la url del blog</mat-label>
                        <input name="urlSlug" formControlName="urlSlug" #urlSlug matInput>
                        <mat-error>El slug es requerido</mat-error>
                        <div *ngIf="blogsForm.get('urlSlug').hasError('pattern')">
                            <mat-error>El Slug debe contener solo letras, números y guiones, y no puede contener espacios.</mat-error>
                          </div>
                    </mat-form-field>
                    <br>
                </div>

                    <mat-form-field>
                        <mat-label>Author</mat-label>
                        <mat-select formControlName="idAuthor">
                            <mat-option *ngFor="let author of dataAuthorsDropdown;" [value]="author.idAuthor">
                                {{author.authorName}}
                            </mat-option>
                        </mat-select>
                        <mat-error>El autor es requerido</mat-error>
                    </mat-form-field> &nbsp;

                </div>
                <div>
                    <app-our-blog-tag-autocomplete
                        (onTagChange)="onTagChange($event)"
                        [defaultValues]="selectedTags"
                    ></app-our-blog-tag-autocomplete>
                </div>
                <br>
                <mat-label class="label-text-editor">Cuerpo del BLOG</mat-label>
                <ckeditor formControlName="htmlBody"></ckeditor>
            </form>

            <div class="submit-button-container">
                <button mat-button (click)="displayBlogs()">Cancelar</button> &nbsp;
                <button form="blogsForm" mat-button>Guardar cambios</button>
            </div>
        </div>


    </div>

    <!--AUTHORS-->

    <div *ngIf="emulatingSection == 'authors'">
        <h4>{{actionTitle}}</h4>
        <br>

        <div *ngIf="!loadAuthors && !createUpdateAuthor">
            <div><br> Espera, se están cargando los datos...
            </div>
            <div class="spinner-border m-2" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div *ngIf="authorSubmit">
            <div><br> Espera, se están guardando los datos...
            </div>
            <div class="spinner-border m-2" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <!-- AUTHORS TABLE -->
        <div *ngIf="loadAuthors && !createUpdateAuthor">
            <div class="container subinternal-menu">
                <button (click)="displayCreateNewAuthor(0, '0', 'Crear Nuevo Author')">Crear Author</button> &nbsp;
            </div>
            <mat-form-field class="filters-container">
                <mat-label>Filtrar Datos</mat-label>
                <input matInput (keyup)="applyFilterAuthors($event)" placeholder="Empieza una busqueda">
            </mat-form-field>
            <div class="table-responsive">
                <table mat-table [dataSource]="dataAuthors" class="mat-elevation-z8 global-table" matSort>

                    <ng-container matColumnDef="imgAuthor">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef> Imagen </th>
                        <td style="padding: 5px 0;" class="tableCells" mat-cell *matCellDef="let element"> <img width="100px" style="border-radius: 50%;" src="{{element.imgAuthor}}" alt=""> </td>
                    </ng-container>

                    <ng-container matColumnDef="authorName">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef mat-sort-header> Nombre </th>
                        <td class="tableCells" mat-cell *matCellDef="let element"> {{element.authorName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="icons">
                        <th class="tableCells" mat-header-cell *matHeaderCellDef> Acciones </th>
                        <td class="tableCells" mat-cell *matCellDef="let element">
                            <a class="button-icons" (click)="displayCreateNewAuthor(element.idAuthor, element, 'Editar Author')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                            <a class="button-icons" (click)="deleteAuthor(element.idAuthor)" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAuthors"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAuthors; "></tr>

                </table>
            </div>
            <mat-paginator id="paginator-jr" [pageSizeOptions]="[50, 150, 250, 400]"></mat-paginator>
        </div>

        <!-- CREATING/UPDATING AUTHOR -->
        <div *ngIf="createUpdateAuthor && !authorSubmit">
            <form style="padding: 5px 20px 5px 20px;" [formGroup]="authorsForm" id="authorsForm" (ngSubmit)="submitAuthor(imgInputAuthor)">
                <br>

                <div style="display: flex;">
                    <div>
                        <label><strong>Imagen del Autor</strong></label> <br>
                        <img style="width: 280px;" src="{{imgSelectedAuthor}}" alt="">
                        <div class="input-file-container">
                            <label class="label-file" for="imgFileAuthor"><i class="fa fa-upload upload-icon" aria-hidden="true"></i>
                Choose
                File</label>
                        </div>
                        <input hidden id="imgFileAuthor" #imgInputAuthor class="input-file" type="file" accept="image/x-png,image/gif,image/jpeg" formControlName="imgAuthor" (change)="onSelectFile($event,'author')">
                        <div *ngIf="submitButtonDirty">
                            <mat-error *ngIf="authorsForm.get('imgAuthor').hasError('required')">
                                La imagen es requerida
                            </mat-error>
                        </div>
                    </div> &nbsp;
                </div>

                <div style="width: 95%;" class="row">

                    <div class="col-sm">
                        <mat-form-field class="example-full-width large-inputs">
                            <mat-label>Nombre del Autor</mat-label>
                            <input name="title" formControlName="authorName" #authorName matInput maxlength="100">
                            <mat-hint align="end">{{authorName.value.length}} / 100</mat-hint>
                            <mat-error>El nombre es requerido</mat-error>
                        </mat-form-field>
                        <br>

                        <mat-form-field class="example-full-width large-inputs">
                            <mat-label>URL perfil de LinkedIn del autor</mat-label>
                            <input name="title" formControlName="urlAuthorLinkedinProfile" matInput>
                            <mat-error>La URL es requerida</mat-error>
                        </mat-form-field>
                        <br>

                        <mat-form-field class="example-full-width large-inputs">
                            <mat-label>Descripción del Autor</mat-label>
                            <textarea formControlName="authorDescription" matInput> </textarea>
                            <mat-error>La descripción es requerida</mat-error>
                        </mat-form-field>

                    </div>

                </div>
            </form>

            <div class="submit-button-container">
                <button mat-button (click)="displayAuthors()">Cancelar</button> &nbsp;
                <button form="authorsForm" mat-button>Guardar cambios</button>
            </div>
        </div>


    </div>

    <app-our-blog-tags *ngIf="emulatingSection == 'tags'"></app-our-blog-tags>
</div>
