import { Component, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { OurBlogCreateDialogComponent } from '../our-blog-create-dialog/our-blog-create-dialog.component';

@Component({
  selector: 'app-our-blog-create-button',
  templateUrl: './our-blog-create-button.component.html'
})
export class OurBlogCreateButtonComponent {
  constructor(
    private readonly dialog: MatDialog
  ) {}

  @Output() onCloseEvent = new EventEmitter<string>();

  openDialog(): void {
    const dialogRef = this.dialog.open(OurBlogCreateDialogComponent);

    dialogRef.afterClosed().subscribe(refetch => {
      this.onCloseEvent.emit(refetch);
    });
  }
}
