import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HomePageService } from '../../services/home-page.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  displayedColumns: string[] = ['titleEng', 'titleEsp', 'icons'];
  displayedColumnsOurTeam: string[] = ['teamMemberName', 'icons'];
  displayedColumnsWhatClientsSay: string[] = ['referenceEng', 'referenceEsp', 'icons'];
  displayedColumnsSuccessStories: string[] = ['storyTitleEng', 'storyTitleEsp', 'icons'];

  progressBar: boolean;
  emulatingSection: string = 'Intro';

  // Forms
  introForm: FormGroup;
  whyCrForm: FormGroup;
  ourTeamForm: FormGroup;
  whatClientsSayForm: FormGroup;
  successStoriesForm: FormGroup;

  // Data From API
  dataPrincipalPage: any;
  dataWhyCr: any;
  dataOurTeam: any;
  dataWhatClientsSay: any;
  dataSuccessStories: any;

  // Others
  dataToSend: any;
  emulatingSubsection: string;
  subsectionSubtitle: string;

  constructor(
    private formBuilder: FormBuilder,
    private homePageService: HomePageService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.introForm = this.formBuilder.group({
      IntroEng: ['', Validators.required],
      IntroEsp: ['', Validators.required]
    });

    this.homePageService.getHomePricipalData().subscribe((res: any) => {
      this.dataPrincipalPage = res[0];
      console.log(this.dataPrincipalPage);
      if (res.length === 0) {
        this.introForm = this.formBuilder.group({
          IntroEng: ['', Validators.required],
          IntroEsp: ['', Validators.required]
        });
      } else {
        this.introForm = this.formBuilder.group({
          IntroEng: [this.dataPrincipalPage.introEng, Validators.required],
          IntroEsp: [this.dataPrincipalPage.introEsp, Validators.required]
        });
      }
    });
  }

  // Principal Page
  submitPrincipalPageForm() {
    if (this.introForm.valid) {
      this.dataToSend = this.introForm.value;
      this.createUpdatePrincipalPage();
    }
  }

  createUpdatePrincipalPage() {
    this.progressBar = true;
    this.homePageService.createUpdateHomePagePrincipal(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }


  // WHY COSTA RICA

  getWhyCrData() {
    this.homePageService.getWhyCrData().subscribe((res: any) => {
      this.dataWhyCr = res;
      console.log(this.dataWhyCr);
    });
  }
  displayWhyCrTable() {
    this.emulatingSection = 'Why CR';
    this.getWhyCrData();
    this.emulatingSubsection = 'View table';

  }

  displayCreateNewWhyCr(id, data, action) {
    this.subsectionSubtitle = action + 'Nuevo';
    this.whyCrForm = this.formBuilder.group({
      id: [id],
      titleEng: [data.titleEng, Validators.required],
      titleEsp: [data.titleEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      bigDescriptionEng: [data.bigDescriptionEng, Validators.required],
      bigDescriptionEsp: [data.bigDescriptionEsp, Validators.required],
      imgBannerUrl: [data.imgBannerUrl, Validators.required],
      imgBannerUrlMobile: [data.imgBannerUrlMobile, Validators.required],
      imgLogoUrl: [data.imgLogoUrl, Validators.required],
      secondBigDescription: [data.secondBigDescription, Validators.required],
      firstItemTitle: [data.firstItemTitle, Validators.required],
      firstItemDescription: [data.firstItemDescription, Validators.required],
      secondItemTitle: [data.secondItemTitle, Validators.required],
      secondItemDescription: [data.secondItemDescription, Validators.required],
      thirdItemTitle: [data.thirdItemTitle, Validators.required],
      thirdItemDescription: [data.thirdItemDescription, Validators.required],
      thirdBigDescription: [data.thirdBigDescription],
      imgSecondBigDescUrl: [data.imgSecondBigDescUrl, Validators.required],
      imgSecondBigDescUrlMobile: [data.imgSecondBigDescUrlMobile, Validators.required],
      promoBarDescription: [data.promoBarDescription, Validators.required]
    });
  }

  submitWhyCr() {
    if (this.whyCrForm.valid) {
      this.dataToSend = this.whyCrForm.value;
      this.createUpdateWhyCr();
    }
  }
  createUpdateWhyCr() {
    this.progressBar = true;
    this.emulatingSubsection = 'View table';
    this.homePageService.createUpdateWhyCr(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
      this.subsectionSubtitle = 'Datos Existentes';
      this.getWhyCrData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  displayUpdateWhyCr(id, data, action) {
    this.subsectionSubtitle = action + 'Datos';
    this.emulatingSubsection = 'Create new Why Cr';
    this.whyCrForm = this.formBuilder.group({
      id: [id],
      titleEng: [data.titleEng, Validators.required],
      titleEsp: [data.titleEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      bigDescriptionEng: [data.bigDescriptionEng, Validators.required],
      bigDescriptionEsp: [data.bigDescriptionEsp, Validators.required],
      imgBannerUrl: [data.imgBannerUrl, Validators.required],
      imgBannerUrlMobile: [data.imgBannerUrlMobile, Validators.required],
      imgLogoUrl: [data.imgLogoUrl, Validators.required],
      secondBigDescription: [data.secondBigDescription, Validators.required],
      firstItemTitle: [data.firstItemTitle, Validators.required],
      firstItemDescription: [data.firstItemDescription, Validators.required],
      secondItemTitle: [data.secondItemTitle, Validators.required],
      secondItemDescription: [data.secondItemDescription, Validators.required],
      thirdItemTitle: [data.thirdItemTitle, Validators.required],
      thirdItemDescription: [data.thirdItemDescription, Validators.required],
      thirdBigDescription: [data.thirdBigDescription],
      imgSecondBigDescUrl: [data.imgSecondBigDescUrl, Validators.required],
      imgSecondBigDescUrlMobile: [data.imgSecondBigDescUrlMobile, Validators.required],
      promoBarDescription: [data.promoBarDescription, Validators.required],
      imgFirstItem: [data.imgFirstItem, Validators.required],
      imgSecondItem: [data.imgSecondItem, Validators.required],
      imgThirdItem: [data.imgThirdItem, Validators.required]
    });
  }

  // What Clients Say

  displayWhatClientsSayTable() {
    this.emulatingSection = 'What Clients Say';
    this.getWhatClientsSayData();
    this.emulatingSubsection = 'View table';
  }
  getWhatClientsSayData() {
    this.homePageService.getWhatClientsSayData().subscribe((res: any) => {
      this.dataWhatClientsSay = res;
    });
  }
  displayCreateWhatClientsSay(id, data, action) {
    this.subsectionSubtitle = action + 'Nuevo';
    this.whatClientsSayForm = this.formBuilder.group({
      id: [id],
      referenceEng: [data.referenceEng, Validators.required],
      referenceEsp: [data.referenceEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      place: [data.place, Validators.required],
    });
  }
  displayUpdateWhatClientsSay(id, data, action) {
    this.subsectionSubtitle = action + 'Datos';
    this.emulatingSubsection = 'Create new What Clients Say';
    this.whatClientsSayForm = this.formBuilder.group({
      id: [id],
      referenceEng: [data.referenceEng, Validators.required],
      referenceEsp: [data.referenceEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      place: [data.place, Validators.required],
    });
  }

  submitWhatClientsSay() {
    if (this.whatClientsSayForm.valid) {
      this.dataToSend = this.whatClientsSayForm.value;
      this.createUpdateWhatClientsSay();
    }
  }
  createUpdateWhatClientsSay() {
    this.progressBar = true;
    this.emulatingSubsection = 'View table';
    this.homePageService.createUpdateWhatClientsSay(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
      this.subsectionSubtitle = 'Datos Existentes';
      this.getWhatClientsSayData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Success Stories


  displaySuccessStoriesTable() {
    this.emulatingSection = 'Success Stories';
    this.getSuccessStoriesData();
    this.emulatingSubsection = 'View table';
  }
  getSuccessStoriesData() {
    this.homePageService.getSuccessStoriesData().subscribe((res: any) => {
      this.dataSuccessStories = res;
      console.log(res);
    });
  }
  displayCreateSuccessStories(id, data, action) {
    this.subsectionSubtitle = action + 'Nuevo';
    this.successStoriesForm = this.formBuilder.group({
      idStory: [id],
      storyTitleEng: [data.storyTitleEng, Validators.required],
      storyTitleEsp: [data.storyTitleEsp, Validators.required],
      storyDescriptionEng: [data.storyDescriptionEng, Validators.required],
      storyDescriptionEsp: [data.storyDescriptionEsp, Validators.required],
    });
  }
  displayUpdateSuccessStories(id, data, action) {
    console.log(id);
    this.subsectionSubtitle = action + 'Datos';
    this.emulatingSubsection = 'Create new Success Stories';
    this.successStoriesForm = this.formBuilder.group({
      idStory: [id],
      storyTitleEng: [data.storyTitleEng, Validators.required],
      storyTitleEsp: [data.storyTitleEsp, Validators.required],
      storyDescriptionEng: [data.storyDescriptionEng, Validators.required],
      storyDescriptionEsp: [data.storyDescriptionEsp, Validators.required],
    });
  }

  submitSuccessStory() {
    if (this.successStoriesForm.valid) {
      this.dataToSend = this.successStoriesForm.value;
      this.createUpdateSuccessStory();
    }
  }
  createUpdateSuccessStory() {
    this.progressBar = true;
    this.emulatingSubsection = 'View table';
    this.homePageService.createUpdateSuccessStories(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
      this.subsectionSubtitle = 'Datos Existentes';
      this.getSuccessStoriesData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // Our Team


  displayOurTeamTable() {
    this.emulatingSection = 'Our Team';
    this.getOurTeamData();
    this.emulatingSubsection = 'View table';
  }
  getOurTeamData() {
    this.homePageService.getOurTeamData().subscribe((res: any) => {
      this.dataOurTeam = res;
    });
  }
  displayCreateOurTeam(id, data, action) {
    this.subsectionSubtitle = action + 'Nuevo';
    this.ourTeamForm = this.formBuilder.group({
      id: [id],
      teamMemberName: [data.teamMemberName, Validators.required],
      positionEng: [data.positionEng, Validators.required],
      positionEsp: [data.positionEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      urlImg: [data.urlImg, Validators.required]
    });
  }
  displayUpdateOurTeam(id, data, action) {
    this.subsectionSubtitle = action + 'Datos';
    this.emulatingSubsection = 'Create new Our Team';
    this.ourTeamForm = this.formBuilder.group({
      id: [id],
      teamMemberName: [data.teamMemberName, Validators.required],
      positionEng: [data.positionEng, Validators.required],
      positionEsp: [data.positionEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      urlImg: [data.urlImg, Validators.required]
    });
  }

  submitOurTeam() {
    if (this.ourTeamForm.valid) {
      this.dataToSend = this.ourTeamForm.value;
      this.createUpdateOurTeam();
    }
  }
  createUpdateOurTeam() {
    this.progressBar = true;
    this.emulatingSubsection = 'View table';
    this.homePageService.createUpdateOurTeam(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
      this.subsectionSubtitle = 'Datos Existentes';
      this.getOurTeamData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  //Others

  delete(id, from) {
    this.openConfirmationMessageDelete(id, from);
  }
  openConfirmationMessageDelete(id, from): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Elemento', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete why cr
        if (from === 'Why Cr') {
          console.log(id, ' ', from);
          this.homePageService.deleteWhyCrItem(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getWhyCrData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        } else
          // Delete Our Team
          if (from === 'Our Team') {
            this.homePageService.deleteOurTeamItem(id).subscribe((response) => {
              this.openSnackBar('¡Eliminado correctamente!');
              this.getOurTeamData();
              this.progressBar = false;
            }, err => {
              this.openSnackBar('¡Error de servidor!');
              console.log(err);
            });
          } else
            // Delete What Clients Say
            if (from === 'What Clients Say') {
              this.homePageService.deleteWhatClientsSayItem(id).subscribe((response) => {
                this.openSnackBar('¡Eliminado correctamente!');
                this.getWhatClientsSayData();
                this.progressBar = false;
              }, err => {
                this.openSnackBar('¡Error de servidor!');
                console.log(err);
              });
            } else
              // Delete Success Story
              if (from === 'Success Stories') {
                this.homePageService.deleteSuccessStoryItem(id).subscribe((response) => {
                  this.openSnackBar('¡Eliminado correctamente!');
                  this.getSuccessStoriesData();
                  this.progressBar = false;
                }, err => {
                  this.openSnackBar('¡Error de servidor!');
                  console.log(err);
                });
              }
      }
    });
  }
  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }
}
