import { Injectable } from '@angular/core';
import { GLOBAL } from './global';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public url: string;
  public token;
  public idCompany;
  public identity;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');
    this.idCompany = localStorage.getItem('idCompany');
  }

  signUp(credentials) {
    return this.http.post(this.url + 'Users/Login', credentials);
}

  getTockenLocalStorage() {
    const token = localStorage.getItem('token');
    if (token !== 'undefined') {
        this.token = token;
    } else {
        this.token = null;
    }
    return this.token;
}

getIdentity() {
  const identity = JSON.parse(localStorage.getItem('identity'));
  if (identity !== 'undefined') {
      this.identity = identity;
  } else {
      this.identity = null;
  }
  return this.identity;
}


}
