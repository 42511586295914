<div class="container global-container">
    <mat-progress-bar *ngIf="progressBar" class="progress-bar" mode="indeterminate"></mat-progress-bar>
    <div class="internal-menu">
        <button (click)="displayPrincipalPage()">Página principal</button> &nbsp;
        <button (click)="displayTeamsPage()">Teams</button> &nbsp;
        <button (click)="displayProfilesPage()">Profiles</button> &nbsp;
        <button (click)="displayTechnologiesPage()">Technologies</button> &nbsp;

    </div> <br> <br> <br>
    <!--PRINCIPAL PAGE-->
    <div *ngIf="emulatingSection == 'Principal Page'">
        <h4>Página principal de Our Services</h4>
        <br>
        <form [formGroup]="principalPageForm" id="principalPageForm" (ngSubmit)="submitPrincipalPage()">

            <!-- ENGLISH-->
            <span class="text-success subtitle">Información en Inglés</span>
            <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título del banner</mat-label>
                <input name="titleEng" formControlName="titleEng" #titleBannerEng matInput maxlength="200">
                <mat-hint align="end">{{titleBannerEng.value.length}} / 200</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción del banner</mat-label>
                <textarea class="height-inputs" matInput name="bannerIntroEng" formControlName="bannerIntroEng"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción de la página principal</mat-label>
                <textarea class="height-inputs" matInput name="firstSectionIntroEng" formControlName="firstSectionIntroEng"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>


            <!-- SPANISH-->
            <span class="text-success subtitle">Certificación en Español</span>
            <br>
            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Título del banner</mat-label>
                <input name="titleEsp" formControlName="titleEsp" #titleBannerEsp matInput maxlength="200">
                <mat-hint align="end">{{titleBannerEsp.value.length}} / 200</mat-hint>
                <mat-error>El título es requerido</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción del banner</mat-label>
                <textarea class="height-inputs" matInput name="bannerIntroEsp" formControlName="bannerIntroEsp"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

            <mat-form-field class="example-full-width large-inputs">
                <mat-label>Introducción de la página principal</mat-label>
                <textarea class="height-inputs" matInput name="firstSectionIntroEsp" formControlName="firstSectionIntroEsp"></textarea>
                <mat-error>La intro es requerida</mat-error>
            </mat-form-field> <br>

        </form>
        <div class="submit-button-container">
            <button form="principalPageForm" mat-button>Guardar cambios</button>
        </div>
    </div>

    <!--TEAMS PAGE-->
    <div *ngIf="emulatingSection == 'Teams Page'">
        <h4>Teams Section</h4>
        <br>
        <div class="container subinternal-menu">
            <button (click)="displayViewTeamsTable()">Ver existentes</button> &nbsp;
            <button (click)="displayCreateNewTeam('0', '0')">Crear Nuevo</button> &nbsp;
            <br> <br>
        </div>

        <!--VIEW TEAMS TABLE-->
        <div *ngIf="emulatingSubsection == 'View Teams table'" class="table-responsive">
            <table mat-table [dataSource]="dataTeams" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Team Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Team Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="deleteTeam(element.id)" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="displayCreateNewTeam(element.id, element)" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <!--CREATE TEAM SECTION-->

        <div *ngIf="emulatingSubsection == 'Creating Team'">
            <form [formGroup]="teamsForm" id="teamsForm" (ngSubmit)="submitTeam()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #title1Eng matInput maxlength="100">
                    <mat-hint align="end">{{title1Eng.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen</mat-label>
                    <input name="imgUrl" formControlName="imgUrl" #urlImgTeam matInput maxlength="3000">
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>

                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #title1Esp matInput maxlength="100">
                    <mat-hint align="end">{{title1Esp.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button form="teamsForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>

    <!--PROFILES-->
    <div *ngIf="emulatingSection == 'Profiles Page'">
        <h4>Profiles Section</h4>
        <br>
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View expertises table'">Ver Expertises y Categorias</button> &nbsp;
            <button (click)="profileSection = 'Expertise'" (click)="displayCreateNewExpertise('0', '0', 'Crear')">Crear Expertise</button> &nbsp;
            <button (click)="profileSection = 'Categoría'" (click)="displayCreateNewExpertise('0', '0', 'Categoría')">Crear Categoría</button> &nbsp;
            <br> <br>
        </div>

        <!--VIEW EXPERTISES TABLE-->

        <div *ngIf="emulatingSubsection == 'View expertises table'" class="table-responsive">
            <h4><strong>Expertices</strong></h4>
            <table mat-table [dataSource]="dataProfilesExpertises" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Expertise Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Expertise Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="profileSection = 'Expertise'" (click)="delete(element.id)" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="profileSection = 'Expertise'" (click)="displayCreateNewExpertise(element.id, element, 'Editar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>
        <br>
        <br>

        <!--VIEW Categories TABLE-->

        <div *ngIf="emulatingSubsection == 'View expertises table'" class="table-responsive">
            <h4><strong>Categorías</strong></h4>
            <table mat-table [dataSource]="dataProfilesCategories" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Expertise Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Expertise Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="profileSection = 'Categoría'" (click)="delete(element.id)" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="profileSection = 'Categoría'" (click)="displayCreateNewExpertise(element.id, element, 'Editar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>

        <!--CREATE Expertise SECTION-->

        <div *ngIf="emulatingSubsection == 'Creating Expertise'">
            <h4><strong>{{profileCreateUpdateActionTitle}} {{profileSection}}</strong></h4>
            <form [formGroup]="expertisesForm" id="expertisesForm" (ngSubmit)="submitExpertise()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #title1Eng matInput maxlength="100">
                    <mat-hint align="end">{{title1Eng.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field *ngIf="profileSection !== 'Expertise'" class="example-full-width large-inputs">
                    <mat-label>Url de la imagen</mat-label>
                    <input name="imgUrl" formControlName="imgUrl" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>


                <!-- SPANISH-->
                <span class="text-success subtitle">ESPAÑOL</span>
                <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEsp" formControlName="titleEsp" #title1Esp matInput maxlength="100">
                    <mat-hint align="end">{{title1Esp.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEsp" formControlName="descriptionEsp"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button form="expertisesForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>

    <!--TECHNOLOGIES PAGE-->
    <div *ngIf="emulatingSection == 'Technologies Page'">
        <div class="container subinternal-menu">
            <button (click)="emulatingSubsection = 'View Technologies Table'">Ver Technologies</button> &nbsp;
            <button (click)="profileSection = 'Technology'" (click)="displayCreateNewTechnology('0', '0', 'Crear')">Crear Technology</button> &nbsp;

            <br> <br>
        </div>
        <h4><strong>Technologies</strong></h4>
        <div *ngIf="emulatingSubsection == 'View Technologies Table'">
            <table mat-table [dataSource]="dataTechnologies" class="mat-elevation-z8">

                <ng-container matColumnDef="titleEng">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Technology Inglés </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEng}} </td>
                </ng-container>

                <ng-container matColumnDef="titleEsp">
                    <th class="tableCells table-header" mat-header-cell *matHeaderCellDef> Technology Español </th>
                    <td class="tableCells table-cel" mat-cell *matCellDef="let element"> {{element.titleEsp}} </td>
                </ng-container>

                <ng-container matColumnDef="icons" stickyEnd>
                    <th class="tableCells" mat-header-cell *matHeaderCellDef></th>
                    <td class="tableCells" mat-cell *matCellDef="let element">
                        <div class="cell-icons">
                            <a (click)="profileSection = 'Technology'" (click)="delete(element.id)" matTooltip="Eliminar">
                                <mat-icon>delete</mat-icon>
                            </a>
                            <a (click)="profileSection = 'Expertise'" (click)="displayCreateNewTechnology(element.id, element, 'Editar')" matTooltip="Editar">
                                <mat-icon>edit</mat-icon>
                            </a>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
            </table>
        </div>


        <!--CREATE TECHNOLOGY-->
        <div *ngIf="emulatingSubsection == 'Creating Technology'">

            <form [formGroup]="technologiesForm" id="technologiesForm" (ngSubmit)="submitTechnology()">
                <!-- ENGLISH-->
                <span class="text-success subtitle">INGLÉS</span>
                <br>
                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Título</mat-label>
                    <input name="titleEng" formControlName="titleEng" #title1Eng matInput maxlength="100">
                    <mat-hint align="end">{{title1Eng.value.length}} / 100</mat-hint>
                    <mat-error>El título es requerido</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Subdescripción</mat-label>
                    <input name="subTitle" formControlName="subTitle" #subTitle0 matInput maxlength="200">
                    <mat-hint align="end">{{subTitle0.value.length}} / 200</mat-hint>
                    <mat-error>La subdescripción es requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Descripción</mat-label>
                    <textarea class="height-inputs" matInput name="descriptionEng" formControlName="descriptionEng"></textarea>
                    <mat-error>La descripción requerida</mat-error>
                </mat-form-field> <br>

                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Url de la imagen</mat-label>
                    <input name="imgUrl" formControlName="imgUrl" matInput>
                    <mat-error>La url es requerida</mat-error>
                </mat-form-field> <br>


                <mat-form-field class="example-full-width large-inputs">
                    <mat-label>Color por defecto</mat-label>
                    <input name="color" formControlName="color" #color matInput maxlength="80">
                    <mat-hint align="end">{{color.value.length}} / 80</mat-hint>
                    <mat-error>El color es requerido</mat-error>
                </mat-form-field> <br>
            </form>
            <div class="submit-button-container">
                <button form="technologiesForm" mat-button>Guardar cambios</button>
            </div>
        </div>
    </div>

</div>