import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OurServicesPageService } from '../../services/our-services-page.service';
import { SnackbarComponent } from '../shared/snackbar/snackbar.component';
import { MatSnackBar, MatDialog } from '@angular/material';
import { ConfirmationMessageComponent } from '../shared/confirmation-message/confirmation-message.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {
  displayedColumns: string[] = ['titleEng', 'titleEsp', 'icons'];

  progressBar: boolean = false;
  emulatingSection: string = 'Principal Page';
  emulatingSubsection: string = 'View Teams table';

  principalPageForm: FormGroup;
  teamsForm: FormGroup;
  expertisesForm: FormGroup;
  technologiesForm: FormGroup;

  dataOurServicePrincipal: any;
  dataToSend: any;
  dataTeams: any;
  dataProfilesExpertises: any;
  dataTeamIndividual: any;
  dataProfilesCategories: any;
  dataTechnologies: any;

  profileSection: string;
  profileCreateUpdateActionTitle: string;

  constructor(
    private formBuilder: FormBuilder,
    private ourServicesPageService: OurServicesPageService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.principalPageForm = this.formBuilder.group({
      titleEng: ['', Validators.required],
      titleEsp: ['', Validators.required],
      bannerIntroEng: ['', Validators.required],
      bannerIntroEsp: ['', Validators.required],
      firstSectionIntroEng: ['', Validators.required],
      firstSectionIntroEsp: ['', Validators.required],
    });
    this.ourServicesPageService.getOurServicesPricipalData().subscribe((res: any) => {
      this.dataOurServicePrincipal = res[0];
      console.log(this.dataOurServicePrincipal);
      if (res.length === 0) {
        this.principalPageForm = this.formBuilder.group({
          titleEng: ['', Validators.required],
          titleEsp: ['', Validators.required],
          bannerIntroEng: ['', Validators.required],
          bannerIntroEsp: ['', Validators.required],
          firstSectionIntroEng: ['', Validators.required],
          firstSectionIntroEsp: ['', Validators.required],
        });
      } else {
        this.principalPageForm = this.formBuilder.group({
          titleEng: [this.dataOurServicePrincipal.titleEng, Validators.required],
          titleEsp: [this.dataOurServicePrincipal.titleEsp, Validators.required],
          bannerIntroEng: [this.dataOurServicePrincipal.bannerIntroEng, Validators.required],
          bannerIntroEsp: [this.dataOurServicePrincipal.bannerIntroEsp, Validators.required],
          firstSectionIntroEng: [this.dataOurServicePrincipal.firstSectionIntroEng, Validators.required],
          firstSectionIntroEsp: [this.dataOurServicePrincipal.firstSectionIntroEsp, Validators.required],
        });
      }
    });
  }

  // PRINCIPAL PAGE
  displayPrincipalPage() {
    this.emulatingSection = 'Principal Page';
  }
  submitPrincipalPage() {
    if (this.principalPageForm.valid) {
      this.dataToSend = this.principalPageForm.value;
      this.createUpdatePrincipalPage();
    }
  }
  createUpdatePrincipalPage() {
    this.progressBar = true;
    console.log(this.dataToSend);
    this.ourServicesPageService.createUpdateOurServicesPrincipal(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.progressBar = false;
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // TEAMS PAGE
  displayTeamsPage() {
    this.emulatingSection = 'Teams Page';
    this.getTeasmData();
  }
  getTeasmData() {
    this.ourServicesPageService.getTeamsData().subscribe((res: any) => {
      this.dataTeams = res;
      console.log(this.dataTeams);
    });
  }
  displayViewTeamsTable() {
    this.emulatingSubsection = 'View Teams table';
  }
  displayCreateNewTeam(id, data) {
    console.log(id);
    this.emulatingSubsection = 'Creating Team';
    this.teamsForm = this.formBuilder.group({
      id: [id],
      titleEng: [data.titleEng, Validators.required],
      titleEsp: [data.titleEsp, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      descriptionEsp: [data.descriptionEsp, Validators.required],
      imgUrl: [data.imgUrl, Validators.required]
    });
  }
  submitTeam() {
    if (this.teamsForm.valid) {
      this.dataToSend = this.teamsForm.value;
      this.createUpdateTeam();
    }
  }
  createUpdateTeam() {
    this.progressBar = true;
    console.log(this.dataToSend);
    this.ourServicesPageService.createUpdateTeam(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'View Teams table';
      this.progressBar = false;
      this.getTeasmData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }
  deleteTeam(id) {
    this.openConfirmationMessage(id);
  }
  openConfirmationMessage(id): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Elemente', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete spanish certification
        this.ourServicesPageService.deleteTeam(id).subscribe((response) => {
          this.openSnackBar('¡Eliminado correctamente!');
          this.getTeasmData();
          this.progressBar = false;
        }, err => {
          this.openSnackBar('¡Error de servidor!');
          console.log(err);
        });


      }
    });
  }


  // PROFILE PAGE
  displayProfilesPage() {
    this.emulatingSection = 'Profiles Page';
    this.getProfilesExpertisesData();
    this.getProfilesCategoriesData();
  }
  getProfilesExpertisesData() {
    this.ourServicesPageService.getProfilesExpertiseData().subscribe((res: any) => {
      this.dataProfilesExpertises = res;
    });
  }
  getProfilesCategoriesData() {
    this.ourServicesPageService.getProfilesCategoryData().subscribe((res: any) => {
      this.dataProfilesCategories = res;
    });
  }

  displayCreateNewExpertise(id, data, action) {
    this.profileCreateUpdateActionTitle = action;
    this.emulatingSubsection = 'Creating Expertise';
    if (this.profileSection === 'Expertise') {
      this.expertisesForm = this.formBuilder.group({
        id: [id],
        titleEng: [data.titleEng, Validators.required],
        titleEsp: [data.titleEsp, Validators.required],
        descriptionEng: [data.descriptionEng, Validators.required],
        descriptionEsp: [data.descriptionEsp, Validators.required]
      });
    } else {
      this.expertisesForm = this.formBuilder.group({
        id: [id],
        titleEng: [data.titleEng, Validators.required],
        titleEsp: [data.titleEsp, Validators.required],
        descriptionEng: [data.descriptionEng, Validators.required],
        descriptionEsp: [data.descriptionEsp, Validators.required],
        imgUrl: [data.imgUrl, Validators.required]
      });
    }
  }

  submitExpertise() {
    if (this.expertisesForm.valid) {
      this.dataToSend = this.expertisesForm.value;
      this.createUpdateExpertise();
    }
  }
  createUpdateExpertise() {
    this.progressBar = true;
    if (this.profileSection === 'Expertise') {
      this.ourServicesPageService.createUpdateExpertise(this.dataToSend).subscribe((res: any) => {
        this.openSnackBar('Datos guardados');
        this.emulatingSubsection = 'View expertises table';
        this.progressBar = false;
        this.getProfilesExpertisesData();
      }, err => {
        this.openSnackBar('Error de servidor');
        console.log(err);
      });
    } else if (this.profileSection === 'Categoría') {
      this.ourServicesPageService.createUpdateCategories(this.dataToSend).subscribe((res: any) => {
        this.openSnackBar('Datos guardados');
        this.emulatingSubsection = 'View expertises table';
        this.progressBar = false;
        this.getProfilesCategoriesData();
      }, err => {
        this.openSnackBar('Error de servidor');
        console.log(err);
      });
    }

  }


  // TECHNOLOGIES PAGE
  displayTechnologiesPage() {
    this.emulatingSection = 'Technologies Page';
    this.getTechnologiesData();
  }
  getTechnologiesData() {
    this.ourServicesPageService.getTechnologiesData().subscribe((res: any) => {
      this.dataTechnologies = res;
      console.log(this.dataTechnologies);
    });
  }

  displayCreateNewTechnology(id, data, action) {
    this.profileCreateUpdateActionTitle = action;
    this.emulatingSubsection = 'Creating Technology';
    this.technologiesForm = this.formBuilder.group({
      id: [id],
      titleEng: [data.titleEng, Validators.required],
      descriptionEng: [data.descriptionEng, Validators.required],
      color: [data.color, Validators.required],
      imgUrl: [data.imgUrl, Validators.required],
      subTitle: [data.subTitle, Validators.required]
    });
  }

  submitTechnology() {
    if (this.technologiesForm.valid) {
      this.dataToSend = this.technologiesForm.value;
      this.createUpdateTechnology();
    }
  }
  createUpdateTechnology() {
    this.progressBar = true;
    this.ourServicesPageService.createUpdateTechnology(this.dataToSend).subscribe((res: any) => {
      this.openSnackBar('Datos guardados');
      this.emulatingSubsection = 'View Technologies Table';
      this.progressBar = false;
      this.getTechnologiesData();
    }, err => {
      this.openSnackBar('Error de servidor');
      console.log(err);
    });
  }

  // OTHERS

  delete(id) {
    this.openConfirmationMessageDelete(id);
  }
  openConfirmationMessageDelete(id): void {
    const dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      data: { 'title': 'Eliminar Elemento', 'description': '¿Deseas eliminarlo?' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.progressBar = true;
        // Delete
        if (this.profileSection === 'Expertise') {
          this.ourServicesPageService.deleteExpertise(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getProfilesExpertisesData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        } else if (this.profileSection === 'Categoría') {
          this.ourServicesPageService.deleteCategories(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getProfilesCategoriesData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        } else if (this.profileSection === 'Technology') {
          console.log('aca es el ID: ' + id);
          this.ourServicesPageService.deleteTechnology(id).subscribe((response) => {
            this.openSnackBar('¡Eliminado correctamente!');
            this.getTechnologiesData();
            this.progressBar = false;
          }, err => {
            this.openSnackBar('¡Error de servidor!');
            console.log(err);
          });
        }

      }
    });
  }
  openSnackBar(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      data: { message: message }, duration: 2000,
    });
  }
}
